import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faReceipt,
  faShoppingBasket,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import "../css/MiniCart.css"; // Import your custom CSS for styling

const MiniCart = ({
  onCloseCart,
  totalQuantity,
  totalAmount,
  updatedTotalAmount,
}) => {
  // Check if totalAmount is a valid number, and then format it
  const formattedTotalAmount =
    typeof updatedTotalAmount === "number"
      ? updatedTotalAmount.toFixed(2)
      : "0.00";

  return (
    <div className="mini-cart">
      <button className="open-cart-button" onClick={() => onCloseCart()}>
        <FontAwesomeIcon className="shoppingbasket" icon={faReceipt} />{" "}
        <span className="minicart-totalquantity">{totalQuantity} </span>
        Totalt:
        <span className="minicart-totalamount">{formattedTotalAmount}</span>
      </button>
    </div>
  );
};

export default MiniCart;
