import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Login.css";

const Login = ({ onLogin }) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Login component mounted");
  }, []);

  const handleLogin = () => {
    console.log("handleLogin function is called");
    const url = `https://atwoonce.com/wp-json/atw/v1/user/?username=${userName}&password=${password}`;
    console.log(`API ${url}`);

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Invalid username or password");
        }
        return response.json();
      })
      .then((userData) => {
        console.log("loginresponse", userData);

        const {
          storeURL,
          consumer_key,
          consumer_secret,
          nets_api_endpoint,
          license,
          klarna_url,
          klarna_key,
          klarna_secret,
          user: { firstname, lastname },
          ...otherUserData
        } = userData;

        const storeData = {
          storeURL,
          consumer_key,
          consumer_secret,
          nets_api_endpoint,
          license,
          klarna_url,
          klarna_key,
          klarna_secret,
          firstname,
          lastname,
        };

        localStorage.setItem("storeData", JSON.stringify(storeData));
        console.log("storeData:", storeData);

        onLogin({
          ...otherUserData,
          storeData,
        });

        navigate("/welcome");
      })
      .catch((error) => {
        setError(error.message);
        console.error("Login error:", error);
      });
  };

  return (
    <div className="login-container">
      <img src="./qlogga.jpg" alt="Visa" className="button-image-klarna" />
      <h2>Logga in</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="input-group">
          <label htmlFor="username">Användarnamn:</label>
          <input
            type="text"
            id="username"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            autoComplete="username"
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Lösenord:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="button" className="loginButton" onClick={handleLogin}>
          Logga in
        </button>
      </form>
    </div>
  );
};

export default Login;
