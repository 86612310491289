const printNonNullProperties = (localModeEventArgs) => {
  let nonNullProperties = "";

  Object.entries(localModeEventArgs).forEach(([key, value]) => {
    if (value !== null) {
      nonNullProperties += `${key}: ${value}\n`;
    }
  });

  return nonNullProperties;
};

const currentDate = new Date();
const formattedDate = currentDate.toISOString().slice(0, 10);
const formattedTime = currentDate.toLocaleTimeString([], {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
});

const printDownloadSoftware = async (downloadSoftwareData) => {
  try {
    const nonNullEventArgs = printNonNullProperties(
      downloadSoftwareData.localModeEventArgs
    );

    const printTextData = `
        ===========================================
        NETS NEDLADDNING MJUKVARA
        Datum:  ${formattedDate} ${formattedTime}
        ===========================================
        ${downloadSoftwareData.printText.text}
        
        ${nonNullEventArgs}
  
    `;

    const fullCommand = `${printTextData}\r\n`;

    const response = await fetch("http://localhost:5000/send-print", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ print_data: fullCommand }),
    });

    const result = await response.json();
    console.log("Print status:", result.status);
    console.log("Från terminalen:", downloadSoftwareData);
  } catch (error) {
    console.error("Error sending print command:", error.message);
  }
};

export default printDownloadSoftware;
