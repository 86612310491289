import React, { useState, useEffect } from "react";
import "./CardTerminal.css"; // Importing the CSS file
import { loginToNets } from "../Nets";
import printNetsReconcilliation from "../receipts/NetsReconcilliation";
import printDownloadDataset from "../receipts/DownloadDataset";
import printDownloadSoftware from "../receipts/DownloadSoftware";
import printLatestTransactionReceipt from "../receipts/PrintLatestTransactionReceipt";
import printLastFinancialResultReceipt from "../receipts/PrintLastFinancialResultReceipt";

const CardTerminal = () => {
  const [terminals, setTerminals] = useState([]);
  const [selectedTerminal, setSelectedTerminal] = useState("");
  const [token, setToken] = useState("");
  const [reconciliations, setReconciliations] = useState([]);
  const [selectedReconciliation, setSelectedReconciliation] = useState(null);
  // Function to handle reconciliation

  // Function to handle reconciliation
  useEffect(() => {
    const fetchData = async () => {
      try {
        // If token is not valid or doesn't exist, login again
        const responseData = await loginToNets();
        // Extract token and terminals from response data
        const { token, terminals } = responseData;
        // Store token and current timestamp in localStorage
        localStorage.setItem("netsToken", token);
        localStorage.setItem("netsTokenTimestamp", Date.now());
        // Store terminals in localStorage
        localStorage.setItem("NetsTerminals", JSON.stringify(terminals));
        // Set terminals in component state
        setTerminals(terminals);
        setToken(token);
        // Check if there are terminals, and if not, throw an error
        if (!terminals || terminals.length === 0) {
          throw new Error("No terminals found");
        }
        // Set a default selected terminal if none is already selected
        if (!selectedTerminal) {
          setSelectedTerminal(terminals[0]); // Select the first terminal
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // Fetch reconciliations from localStorage
    const storedReconciliations = localStorage.getItem("reconciliations");
    if (storedReconciliations) {
      setReconciliations(JSON.parse(storedReconciliations));
    }
  }, [selectedTerminal]);

  const handleReconciliation = async () => {
    try {
      // If no terminal is selected, throw an error
      if (!selectedTerminal) {
        throw new Error("Ingen terminal vald");
      }

      // Retrieve token and storeData from localStorage
      const token = localStorage.getItem("netsToken");
      const storeData = JSON.parse(localStorage.getItem("storeData"));

      if (!token) {
        throw new Error("Nets token not found in localStorage");
      }

      // Ensure storeData is retrieved and contains nets_api_endpoint
      if (!storeData || !storeData.nets_api_endpoint) {
        console.error(
          "storeData or nets_api_endpoint is not found in local storage"
        );
        return;
      }

      const nets_api_endpoint = storeData.nets_api_endpoint;

      // Add your logic here for reconciliation
      console.log("Reconciliation function called");

      // Send a reconciliation request to the terminal
      const response = await fetch(
        `${nets_api_endpoint}/v1/terminal/${selectedTerminal}/administration`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            action: "reconciliation",
            operatorId: "1234",
          }),
        }
      );

      console.log("Reconciliation response:", response);

      // Check if the request was successful
      if (response.ok) {
        // Parse response body as JSON
        const responseData = await response.json();
        console.log("Reconciliation data:", responseData);

        // Check if there's any result in the response data
        if (responseData.result) {
          // You can access the reconciliation data here
          const reconciliationData = responseData.result.reconciliation;
          console.log("Reconciliation result:", reconciliationData);
          await printNetsReconcilliation(reconciliationData);
        } else {
          console.error("Error: No reconciliation result found in response");
        }
      } else {
        console.error("Error reconciliation action:", response.statusText);
      }
    } catch (error) {
      // Handle network errors or exceptions
      console.error("Error reconciliation action:", error);
    }
  };

  const handleRowClick = (reconciliation) => {
    setSelectedReconciliation(reconciliation);
  };

  const handleClosePopup = () => {
    setSelectedReconciliation(null);
  };

  // Function to handle loading datasets
  const handleLoadDatasets = async () => {
    // Add your logic here for loading datasets
    console.log("Load datasets function called");
    try {
      // If no terminal is selected, throw an error
      if (!selectedTerminal) {
        throw new Error("Ingen terminal vald");
      }

      // Retrieve token and storeData from localStorage
      const token = localStorage.getItem("netsToken");
      const storeData = JSON.parse(localStorage.getItem("storeData"));

      if (!token) {
        throw new Error("Nets token not found in localStorage");
      }

      // Ensure storeData is retrieved and contains nets_api_endpoint
      if (!storeData || !storeData.nets_api_endpoint) {
        console.error(
          "storeData or nets_api_endpoint is not found in local storage"
        );
        return;
      }

      const nets_api_endpoint = storeData.nets_api_endpoint;

      // Add your logic here for reconciliation
      console.log("Download Dataset function called");

      // Send a download dataset request to the terminal
      const response = await fetch(
        `${nets_api_endpoint}/v1/terminal/${selectedTerminal}/administration`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            action: "downloadDataset",
          }),
        }
      );

      console.log("Download Dataset response:", response);

      // Check if the request was successful
      if (response.ok) {
        // Parse response body as JSON
        const responseData = await response.json();
        console.log("Download Dataset data:", responseData);

        // Check if there's any result in the response data
        if (responseData.result) {
          // You can access the download dataset data here
          const downloadDatasetData = responseData.result;
          console.log("Download Dataset result:", responseData.result);
          printDownloadDataset(downloadDatasetData);
        } else {
          console.error("Error: No Download Dataset result found in response");
        }
      } else {
        console.error("Error Download Dataset action:", response.statusText);
      }
    } catch (error) {
      // Handle network errors or exceptions
      console.error("Error Download Dataset action:", error);
    }
  };

  // Function to handle updating datasets
  const handleUpdateDatasets = () => {
    // Add your logic here for updating datasets
    console.log("Update datasets function called");
  };

  // Function to handle software download
  const handleSoftwareDownload = async () => {
    // Add your logic here for software download
    console.log("Software download function called");
    try {
      // If no terminal is selected, throw an error
      if (!selectedTerminal) {
        throw new Error("Ingen terminal vald");
      }

      // Retrieve token and storeData from localStorage
      const token = localStorage.getItem("netsToken");
      const storeData = JSON.parse(localStorage.getItem("storeData"));

      if (!token) {
        throw new Error("Nets token not found in localStorage");
      }

      // Ensure storeData is retrieved and contains nets_api_endpoint
      if (!storeData || !storeData.nets_api_endpoint) {
        console.error(
          "storeData or nets_api_endpoint is not found in local storage"
        );
        return;
      }

      const nets_api_endpoint = storeData.nets_api_endpoint;

      // Add your logic here for reconciliation
      console.log("Download Software function called");

      // Send a download software request to the terminal
      const response = await fetch(
        `${nets_api_endpoint}/v1/terminal/${selectedTerminal}/administration`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            action: "downloadSoftware",
          }),
        }
      );

      console.log("Download Software response:", response);

      // Check if the request was successful
      if (response.ok) {
        // Parse response body as JSON
        const responseData = await response.json();
        console.log("Download Software data:", responseData);

        // Check if there's any result in the response data
        if (responseData.result) {
          // You can access the download software data here
          const downloadSoftwareData = responseData.result;
          console.log("Download Software result:", responseData.result);
          printDownloadSoftware(downloadSoftwareData);
        } else {
          console.error("Error: No Download Software result found in response");
        }
      } else {
        console.error("Error Download Software action:", response.statusText);
      }
    } catch (error) {
      // Handle network errors or exceptions
      console.error("Error Download Software action:", error);
    }
  };

  // Function to handle software download at night
  const handleSoftwareDownloadAtNight = () => {
    // Add your logic here for software download at night
    console.log("Software download at night function called");
  };

  // Function to handle printing last receipt
  const handlePrintLastReceipt = async () => {
    // Add your logic here for printing last receipt
    console.log("Print last receipt function called");
    try {
      // If no terminal is selected, throw an error
      if (!selectedTerminal) {
        throw new Error("Ingen terminal vald");
      }

      // Retrieve token and storeData from localStorage
      const token = localStorage.getItem("netsToken");
      const storeData = JSON.parse(localStorage.getItem("storeData"));

      if (!token) {
        throw new Error("Nets token not found in localStorage");
      }

      // Ensure storeData is retrieved and contains nets_api_endpoint
      if (!storeData || !storeData.nets_api_endpoint) {
        console.error(
          "storeData or nets_api_endpoint is not found in local storage"
        );
        return;
      }

      const nets_api_endpoint = storeData.nets_api_endpoint;

      // Add your logic here for reconciliation
      console.log("Print Latest Transaction Receipt function called");

      // Send a print latest transaction receipt request to the terminal
      const response = await fetch(
        `${nets_api_endpoint}/v1/terminal/${selectedTerminal}/administration`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            action: "printLatestTransactionReceipt",
          }),
        }
      );

      console.log("Print Latest Transaction Receipt response:", response);

      // Check if the request was successful
      if (response.ok) {
        // Parse response body as JSON
        const responseData = await response.json();
        console.log("Print Latest Transaction Receipt data:", responseData);

        // Check if there's any result in the response data
        if (responseData.result) {
          // You can access the print latest transaction receipt data here
          const LatestTransactionReceiptData = responseData.result;
          console.log(
            "Print Latest Transaction Receipt result:",
            responseData.result
          );
          printLatestTransactionReceipt(LatestTransactionReceiptData);
        } else {
          console.error(
            "Error: No Print Latest Transaction Receipt result found in response"
          );
        }
      } else {
        console.error(
          "Error Print Latest Transaction Receipt action:",
          response.statusText
        );
      }
    } catch (error) {
      // Handle network errors or exceptions
      console.error("Error Print Latest Transaction Receipt action:", error);
    }
  };

  // Function to handle last financial result
  const handleLastFinancialResult = async () => {
    // Add your logic here for last financial result
    console.log("Last financial result function called");
    try {
      // If no terminal is selected, throw an error
      if (!selectedTerminal) {
        throw new Error("Ingen terminal vald");
      }

      // Retrieve token and storeData from localStorage
      const token = localStorage.getItem("netsToken");
      const storeData = JSON.parse(localStorage.getItem("storeData"));

      if (!token) {
        throw new Error("Nets token not found in localStorage");
      }

      // Ensure storeData is retrieved and contains nets_api_endpoint
      if (!storeData || !storeData.nets_api_endpoint) {
        console.error(
          "storeData or nets_api_endpoint is not found in local storage"
        );
        return;
      }

      const nets_api_endpoint = storeData.nets_api_endpoint;

      // Add your logic here for reconciliation
      console.log("Print Last financial result function called");

      // Send a request to get the last financial result from the terminal
      const response = await fetch(
        `${nets_api_endpoint}/v1/terminal/${selectedTerminal}/administration`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            action: "getLatestTransactionResult",
          }),
        }
      );

      console.log("Print Last financial result response:", response);

      // Check if the request was successful
      if (response.ok) {
        // Parse response body as JSON
        const responseData = await response.json();
        console.log("Print Last financial result data:", responseData);

        if (responseData.result) {
          const LastFinancialResultReceiptData = responseData.result;
          console.log(
            "Print Last financial result result:",
            responseData.result
          );
          printLastFinancialResultReceipt(LastFinancialResultReceiptData);
        } else {
          console.error(
            "Error: No Print Last financial result result found in response"
          );
        }
      } else {
        console.error(
          "Error Print Last financial result action:",
          response.statusText
        );
      }
    } catch (error) {
      // Handle network errors or exceptions
      console.error("Error Last financial result Receipt action:", error);
    }
  };

  return (
    <div>
      <h3>Administration för kortterminal</h3>
      <div>
        <p>Terminaler:</p>
        <select
          value={selectedTerminal}
          onChange={(e) => setSelectedTerminal(e.target.value)}
        >
          {terminals.map((terminal) => (
            <option key={terminal} value={terminal}>
              {terminal}
            </option>
          ))}
        </select>
      </div>
      <div className="grid-container-cardadmin">
        <div>
          <button
            className="reconciliation-button"
            onClick={handleReconciliation}
          >
            Dagsavslut
          </button>
        </div>
        <div>
          <button className="handleloaddataset" onClick={handleLoadDatasets}>
            Ladda dataset
          </button>
        </div>
        <div>
          <button
            className="handleupdatedataset"
            onClick={handleUpdateDatasets}
          >
            Uppdatera dataset
          </button>
        </div>
        <div>
          <button
            className="handlesoftwaredownload"
            onClick={handleSoftwareDownload}
          >
            Ladda ned mjukvara
          </button>
        </div>
        <div>
          <button
            className="handlesoftwaredownloadatnight"
            onClick={handleSoftwareDownloadAtNight}
          >
            Ladda ned mjukvara på natten
          </button>
        </div>
        <div>
          <button
            className="handleprintlastreceipt"
            onClick={handlePrintLastReceipt}
          >
            Skriv ut senaste transaktion
          </button>
        </div>
        <div>
          <button
            className="handlelastfinancialresult"
            onClick={handleLastFinancialResult}
          >
            Senaste finansiella resultat
          </button>
        </div>
      </div>
      <h3>Historiska Dagsavslut</h3>
      {reconciliations.length === 0 ? (
        <p>Inga Dagsavslut hittade</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Datum</th>
              <th>Tid</th>
              <th>Händelse</th>
            </tr>
          </thead>
          <tbody>
            {reconciliations
              .slice()
              .sort((a, b) => {
                const dateA = new Date(`${a.date}T${a.time}`);
                const dateB = new Date(`${b.date}T${b.time}`);
                return dateB - dateA; // Sort in descending order
              })
              .map((reconciliation, index) => (
                <tr key={index} onClick={() => handleRowClick(reconciliation)}>
                  <td>{reconciliation.date}</td>
                  <td>{reconciliation.time}</td>
                  <td>Visa detaljer</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      {selectedReconciliation && (
        <div className="popup">
          <div className="popup-content">
            <button onClick={handleClosePopup}>Stäng</button>
            <pre style={{ whiteSpace: "pre-wrap" }}>
              {selectedReconciliation.receipt}
            </pre>
          </div>
        </div>
      )}

      <style jsx>{`
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th,
        td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
        tr:hover {
          background-color: #f1f1f1;
          cursor: pointer;
        }
        .popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .popup-content {
          background-color: white;
          padding: 20px;
          border-radius: 5px;
          max-width: 80%;
          max-height: 80%;
          overflow-y: auto;
        }
      `}</style>
    </div>
  );
};

export default CardTerminal;
