import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Welcome from "./components/Welcome";
import Login from "./components/Login";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const storedStoreData = localStorage.getItem("storeData");
  const [storeData, setStoreData] = React.useState(
    storedStoreData ? JSON.parse(storedStoreData) : null
  );

  React.useEffect(() => {
    console.log("React app has mounted");
  }, []);

  const handleLogin = (userData) => {
    console.log("User logged in:", userData);
    setIsLoggedIn(true);
    setStoreData(userData.storeData);
    console.log("storeData", userData.storeData);
  };

  const handleLogout = () => {
    console.log("logging out");
    setIsLoggedIn(false);
    setStoreData(null);
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/welcome" element={<Welcome storeData={storeData} />} />
        <Route
          path="/"
          element={<Navigate to="/login" onLogout={handleLogout} />}
        />
      </Routes>
    </Router>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

export default App;
