import React, { Component } from "react";
import ProductList from "./ProductList";
import "../css/Welcome.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import SettingsLogin from "./SettingsLogin";

class Welcome extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      products: [],
      categories: [],
      taxes: [],
      productAddons: [],
      currentpage: "",
    };
  }

  async componentDidMount() {
    try {
      let allProducts = [];
      let currentPage = 1;
      let totalPages = 1;
      let encounteredProductIds = new Set(); // Keep track of product IDs

      // Fetch products while there are more pages to retrieve
      while (currentPage <= totalPages) {
        const response = await fetch(
          `${this.props.storeData.storeURL}/wp-json/wc/v3/products?per_page=100&page=${currentPage}`,
          {
            method: "GET",
            headers: {
              Authorization: `Basic ${btoa(
                `${this.props.storeData.consumer_key}:${this.props.storeData.consumer_secret}`
              )}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch products. Status: ${response.status}`
          );
        }

        const data = await response.json();

        // Filter out duplicate products based on product ID
        const filteredData = data.filter(
          (product) => !encounteredProductIds.has(product.id)
        );

        allProducts = allProducts.concat(filteredData);

        // Add product IDs to the set
        data.forEach((product) => encounteredProductIds.add(product.id));

        totalPages = parseInt(response.headers.get("X-WP-TotalPages"));
        currentPage++;
      }

      this.setState({
        isLoading: false,
        products: allProducts,
      });

      // Store products data in localStorage
      localStorage.setItem("products", JSON.stringify(allProducts));
    } catch (error) {
      console.error(error);
      this.setState({ isLoading: false });
    }

    fetch(
      `${this.props.storeData.storeURL}/wp-json/wc/v3/products/categories?per_page=20`,
      {
        method: "GET",
        headers: {
          Authorization: `Basic ${btoa(
            `${this.props.storeData.consumer_key}:${this.props.storeData.consumer_secret}`
          )}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          categories: data,
        });

        // Store categories data in localStorage
        localStorage.setItem("categories", JSON.stringify(data));
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.error(error);
      });

    fetch(`${this.props.storeData.storeURL}/wp-json/wc/v3/taxes/`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa(
          `${this.props.storeData.consumer_key}:${this.props.storeData.consumer_secret}`
        )}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          taxes: data,
        });

        // Store categories data in localStorage
        localStorage.setItem("taxes", JSON.stringify(data));
        this.setState({ isLoading: false });
        console.log("taxes", data);
      })
      .catch((error) => {
        console.error(error);
      });

    fetch(`${this.props.storeData.storeURL}/wp-json/atWoonce/v1/getyithdata`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        // Modify the productAddons data before storing it in localStorage
        const updatedProductAddons = JSON.parse(JSON.stringify(data)); // Deep copy of productAddons

        updatedProductAddons.addons.forEach((addon) => {
          const productIDs = addon.options.product;
          if (Array.isArray(productIDs)) {
            // Check if productIDs is an array
            const matchedProducts = []; // Array to store matched products
            const prices = productIDs.map((productID) => {
              const product = this.state.products.find(
                (p) => p.id.toString() === productID
              );
              if (product) {
                matchedProducts.push(product); // Add matched product to the array
                return product.price.toString(); // Convert price to string
              } else {
                return ""; // Return empty string if product not found
              }
            });

            console.log("Matched products:", matchedProducts); // Log matched products
            addon.options.price = prices; // Update the price array
          } else {
            console.info("Product IDs are not an array:", productIDs);
          }

          const labels = addon.options.label;
          if (Array.isArray(labels)) {
            // Check if labels is an array
            const updatedLabels = labels.map((label) => {
              // Check if the label contains '(#'
              const index = label.indexOf("(#");
              if (index !== -1) {
                // Remove the content inside the parentheses and trim the label
                return label.slice(0, index).trim();
              } else {
                // If '(#' is not found, return the original label
                return label;
              }
            });

            addon.options.label = updatedLabels; // Update the labels
          }
        });

        // Store modified product addons data in localStorage
        localStorage.setItem(
          "productAddons",
          JSON.stringify(updatedProductAddons)
        );

        this.setState({
          productAddons: updatedProductAddons, // Set the modified product addons data in state
        });

        // Check if both products, categories, and product addons data are available
        if (
          !this.state.isLoading &&
          this.state.products.length > 0 &&
          this.state.categories.length > 0
        ) {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Function to update the currentpage state
  setCurrentPage = (page) => {
    this.setState({ currentpage: page });
  };

  render() {
    const {
      isLoading,
      products,
      categories,
      taxes,
      productAddons,
      currentpage,
    } = this.state;

    if (isLoading) {
      return (
        <p className="welcomeloading">
          <FontAwesomeIcon icon={faSpinner} spin />
        </p>
      );
    }

    return (
      <div>
        {currentpage !== "Settings" ? (
          <ProductList
            products={products}
            categories={categories}
            taxes={taxes}
            productAddons={productAddons}
            setCurrentPage={this.setCurrentPage} // Pass setCurrentPage as a prop
          />
        ) : (
          <SettingsLogin setCurrentPage={this.setCurrentPage} />
        )}
      </div>
    );
  }
}

export default Welcome;
