import React, { useEffect, useState } from "react";
import "./Account.css";

const Account = () => {
  const [storeData, setStoreData] = useState(null);

  useEffect(() => {
    // Retrieve storeData from localStorage and parse it
    const storedData = localStorage.getItem("storeData");
    if (storedData) {
      setStoreData(JSON.parse(storedData));
    }
  }, []);

  if (!storeData) {
    return <div>Loading...</div>;
  }

  const maskValue = (value) => value.replace(/.(?=.{4})/g, "*");

  return (
    <div className="account-container">
      <h1>Kontoinformation</h1>
      <div className="account-info">
        <div>
          <strong>Webshop URL:</strong> {storeData.storeURL}
        </div>
        <div>
          <strong>Förnamn:</strong> {storeData.firstname}
          <br></br>
          <strong>Efternamn:</strong> {storeData.lastname}
        </div>
        <div>
          <strong>License:</strong> {maskValue(storeData.license)}
        </div>
        <div>
          <strong>Consumer Secret:</strong>{" "}
          {maskValue(storeData.consumer_secret)}
        </div>
        <div>
          <strong>Consumer Key:</strong> {maskValue(storeData.consumer_key)}
        </div>

        <div>
          <strong>Klarna URL:</strong> {storeData.klarna_url}
        </div>
        <div>
          <strong>Klarna Key:</strong> {maskValue(storeData.klarna_key)}
        </div>
        <div>
          <strong>Klarna Secret:</strong> {maskValue(storeData.klarna_secret)}
        </div>

        <div>
          <strong>Nets API Endpoint:</strong> {storeData.nets_api_endpoint}
        </div>
      </div>
    </div>
  );
};

export default Account;
