import React, { useState } from "react";
import "../css/SettingsLogin.css"; // Import your custom CSS for styling
import "./Settings";
import Settings from "./Settings";

const SettingsLogin = ({ setCurrentPage }) => {
  const [pin, setPin] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleDigitClick = (digit) => {
    setPin((prevPin) => (prevPin === "0" ? digit : `${prevPin}${digit}`));
  };

  const handleClearClick = () => {
    setPin("");
  };

  const handleLoginClick = () => {
    if (pin === "2580") {
      // Update the state to indicate that the user is logged in
      setIsLoggedIn(true);
    } else {
      // Display an error message or take other actions on unsuccessful login
      alert(
        "Fel PIN. OBS! Försök inte igen om du inte är behörig att utföra denna funktion."
      );
      // Clear entered PIN
      setPin("");
    }
  };

  const gotoProductsPage = () => {
    setCurrentPage("");
  };

  // Render different content based on login status
  if (isLoggedIn) {
    return <Settings setCurrentPage={gotoProductsPage} />;
  } else {
    return (
      <div className="setingsLoginBody">
        <div className="settingslogin-container">
          <h1>Inloggning till Inställningar</h1>
          <div className="settingslogin-keypad-row">
            <input
              className="settingsLoginInput"
              type="password"
              value={pin}
              readOnly
            />
          </div>
          <div className="settingslogin-keypad">
            {Array.from({ length: 9 }, (_, index) => (
              <button
                className="settingsLogonKeypad"
                key={index}
                onClick={() => handleDigitClick(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button className="settingsLogonKeypad" onClick={handleClearClick}>
              Rensa
            </button>
            <button
              className="settingsLogonKeypad"
              onClick={() => handleDigitClick(0)}
            >
              0
            </button>
            <button className="settingsLogonKeypad" onClick={handleLoginClick}>
              Logga in
            </button>
          </div>
          <button
            className="settingsLogonKeypadBack"
            onClick={gotoProductsPage}
          >
            TILLBAKA
          </button>
        </div>
      </div>
    );
  }
};

export default SettingsLogin;
