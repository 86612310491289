const printDownloadDataset = async (downloadDatasetData) => {
  try {
    // Check if printText exists and has a text property
    const printText =
      downloadDatasetData.printText && downloadDatasetData.printText.text
        ? downloadDatasetData.printText.text
        : "";

    const printTextData = `
        ===========================================
        NETS AVSTÄMNING DATASET
        ===========================================
        ${printText}
        
    `;

    const fullCommand = `${printTextData}\r\n`;

    const response = await fetch("http://localhost:5000/send-print", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ print_data: fullCommand }),
    });

    const result = await response.json();
    console.log("Print status:", result.status);
    console.log("Från terminalen:", downloadDatasetData);
  } catch (error) {
    console.error("Error sending print command:", error.message);
  }
};

export default printDownloadDataset;
