import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Settings.css"; // Import your custom CSS for styling
import CardTerminal from "./settings/CardTerminal";
import Sales from "./settings/Sales";
import Account from "./settings/Account";

const Settings = ({ onLogout, setCurrentPage }) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("general");
  const [inputString, setInputString] = useState("");
  const [outputString, setOutputString] = useState("");

  // Retrieve settingsData from local storage
  const storedStoreData = localStorage.getItem("storeData");

  // Parse the stored data (if it exists)
  const parsedStoreData = storedStoreData ? JSON.parse(storedStoreData) : {};

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const gotoProductsPage = () => {
    window.location.reload();
  };

  const handleLogout = () => {
    try {
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const sendStringToServer = async () => {
    try {
      const command = "kd";
      const commandArguments =
        "202312031955 5566775566 Pos01 1325 normal 0,00 1,00 0,00;0,00 25,00;0,20 12,00;0,00 6,00;0,00";
      const crc = "0000";
      const fullCommand = `${command} ${commandArguments} ${crc}\r\n`;

      const response = await fetch("http://localhost:5000/send-command", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ command: fullCommand }),
      });

      const result = await response.json();
      setInputString(fullCommand);
      setOutputString(result.response);
    } catch (error) {
      console.error("Error sending command:", error.message);
    }
  };

  const sendPrintToServer = async () => {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().slice(0, 10);
      const formattedTime = currentDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      const receiptData = `
  ===========================================
  ${parsedStoreData.storeURL}
  ===========================================
  Datum:  ${formattedDate} ${formattedTime}
  Transaction ID: 123456789
  ===========================================
  Item                      Qty  Price
  -------------------------------------------
  Item 1                    2    $10.00
  Item 2                    1    $5.00
  Item 3                    3    $15.00
  -------------------------------------------
  Subtotal                       $30.00
  Tax (8%)                       $2.40
  Total                          $32.40
  ===========================================
        Thank you for shopping with us!
  ===========================================



`;

      const fullCommand = `${receiptData}\r\n`;

      const response = await fetch("http://localhost:5000/send-print", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ print_data: fullCommand }),
      });

      const result = await response.json();
      console.log("Print status:", result.status);
    } catch (error) {
      console.error("Error sending print command:", error.message);
    }
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case "general":
        return (
          <div className="settings-section">
            <h2>Allmänt</h2>
            <div className="settings-section">
              <h2>Appearance</h2>
              <p>
                <strong>Theme:</strong> Light
              </p>
            </div>
            <div className="settings-section">
              <h2>Notification Settings</h2>
              <p>
                <strong>Receive Notifications:</strong> Enabled
              </p>
            </div>
          </div>
        );
      case "account":
        return <Account />;
      case "printer":
        return (
          <div className="settings-section">
            <h2>Skrivare</h2>
            <button onClick={sendPrintToServer}>Test Print</button>
            <label>Print status:</label>
          </div>
        );
      case "kontrollenhet":
        return (
          <div className="settings-section">
            <h2>Kontrollenhet</h2>

            <div className="settings-section">
              <h2>Communication with USB Unit</h2>
              <label htmlFor="inputString">Input String:</label>
              <input
                type="text"
                id="inputString"
                value={inputString}
                onChange={(e) => setInputString(e.target.value)}
              />
              <button onClick={sendStringToServer}>Send String</button>

              <p>
                <strong>Output String from USB Unit:</strong> {outputString}
              </p>
            </div>
          </div>
        );
      case "cardTerminal":
        return <CardTerminal />;
      case "sales-transactions":
        return <Sales />;
      default:
        return null;
    }
  };

  return (
    <div className="settings-container">
      <div className="settings-menu">
        <ul>
          <li onClick={() => handleTabChange("general")}>Allmänt</li>
          <li onClick={() => handleTabChange("account")}>Konto</li>
          <li onClick={() => handleTabChange("printer")}>Skrivare</li>
          <li onClick={() => handleTabChange("kontrollenhet")}>
            Kontrollenhet
          </li>
          <li onClick={() => handleTabChange("cardTerminal")}>Kortterminal</li>
          <li onClick={() => handleTabChange("sales-transactions")}>
            Försäljning & Transaktioner
          </li>
          {/* Add other tab items here */}
        </ul>
      </div>
      <div className="settings-content">
        <h1>Inställningar</h1>
        {renderTabContent()}
        {/* Common settings content */}

        {/* Logout button */}
        <button className="settingsLogoutButton" onClick={handleLogout}>
          Logout
        </button>
        {/* Back button */}
        <button className="settingsLogonKeypadBack" onClick={gotoProductsPage}>
          TILLBAKA
        </button>
      </div>
    </div>
  );
};

export default Settings;
