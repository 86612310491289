const printNetsReconciliation = async (reconciliationData) => {
  try {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10);
    const formattedTime = currentDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    const headerData = `
  ===========================================
  NETS DAGSAVSLUT
  ===========================================
  Site Number: ${reconciliationData.header.siteNumber}
  Session Number: ${reconciliationData.header.sessionNumber}
  Timestamp: ${reconciliationData.header.timestamp}
  `;

    const batchesData = `
  ===========================================
  BUNTNUMMER
  ===========================================
  ${reconciliationData.batches
    .map(
      (batch) =>
        `Issuer ID: ${batch.issuerId}
    Transactions: ${batch.transactions}
    Amount Total: ${batch.amountTotal}
    Amount Cash: ${batch.amountCash}
    Amount Deposit: ${batch.amountDeposit}
    -------------------------------------------
    `
    )
    .join("")}
  `;

    const printTextData = `
  ===========================================
  AVSTÄMNING DAGSAVSLUT
  ===========================================
  ${reconciliationData.printText.text}
  `;

    const fullCommand = `${headerData}\r\n${batchesData}\r\n${printTextData}\r\n`;

    // Save the reconciliation data along with the date and time to localStorage
    const storedReconciliations = localStorage.getItem("reconciliations");
    const parsedReconciliations = storedReconciliations
      ? JSON.parse(storedReconciliations)
      : [];
    parsedReconciliations.push({
      date: formattedDate,
      time: formattedTime,
      receipt: fullCommand,
    });
    localStorage.setItem(
      "reconciliations",
      JSON.stringify(parsedReconciliations)
    );

    const response = await fetch("http://localhost:5000/send-print", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ print_data: fullCommand }),
    });

    const result = await response.json();
    console.log("Print status:", result.status);
    console.log("Från terminalen:", reconciliationData);
  } catch (error) {
    console.error("Error sending print command:", error.message);
  }
};

export default printNetsReconciliation;
