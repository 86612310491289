const printLastFinancialResultReceipt = async (
  LastFinancialResultReceiptData
) => {
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().slice(0, 10);
  const formattedTime = currentDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  try {
    const timestamp = LastFinancialResultReceiptData.timeStamp; // Corrected property name
    const transactionsId = LastFinancialResultReceiptData.transactionId; // Corrected property name
    const transactionType = LastFinancialResultReceiptData.transactionType; // Corrected property name

    const localModeEventArgs =
      LastFinancialResultReceiptData.latestTransactionResult;

    const filteredTransactionResult = Object.fromEntries(
      Object.entries(localModeEventArgs)
        .filter(([key]) => key[0] === key[0].toUpperCase())
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Sort keys alphabetically
    );

    // Format filteredTransactionResult as key-value pairs
    const formattedFilteredResult = Object.entries(filteredTransactionResult)
      .map(([key, value]) => `${key}: ${value}`)
      .join("\n");

    const printTextData = `
        ===========================================
        NETS SENASTE FINANSRESULTAT
        ===========================================
        Beställningstid: ${formattedDate} ${formattedTime}
        Tidstämpel: ${timestamp || "N/A"}
        TransaktionsId: ${transactionsId || "N/A"}
        TransaktionsTyp: ${transactionType || "N/A"}
        ===========================================
        ${formattedFilteredResult}
  
    `;

    const fullCommand = `${printTextData}\r\n`;

    const response = await fetch("http://localhost:5000/send-print", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ print_data: fullCommand }),
    });

    const result = await response.json();
    console.log("Print status:", result.status);
    console.log("Från terminalen:", LastFinancialResultReceiptData);
  } catch (error) {
    console.error("Error sending print command:", error.message);
  }
};

export default printLastFinancialResultReceipt;
