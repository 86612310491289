async function loginToNets() {
  // Retrieve storeData from localStorage and parse it
  const storeData = JSON.parse(localStorage.getItem("storeData"));

  // Ensure storeData is retrieved and contains nets_api_endpoint
  if (!storeData || !storeData.nets_api_endpoint) {
    console.error(
      "storeData or nets_api_endpoint is not found in local storage"
    );
    return;
  }

  const nets_api_endpoint = storeData.nets_api_endpoint;
  const url = `${nets_api_endpoint}/v1/login`;
  const credentials = {
    username: "test1_asa_computing_company_ab",
    password: "#E*^Pc3p@27xgF&&",
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    });

    if (!response.ok) {
      throw new Error(`Failed to login to Nets API: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error logging in to Nets API:", error);
    throw error;
  }
}

export { loginToNets };
