const responseCodes = {
  "Authorisation Server": {
    "00": "Betalningen har godkänts.",
    "03": "Det angivna BAX-numret är ogiltigt.",
    "13": "Beloppet är ogiltigt.",
    "15": "Utgivaren av kortet existerar inte.",
    "22": "Det finns misstanke om funktionsfel.",
    "25": "Posten kunde inte hittas.",
    "30": "Formateringsfel har uppstått.",
    "34": "Fel kortnummer (kontrollsiffra).",
    "40": "Processkoden är okänd.",
    "43": "Kortet är stulet.",
    "51": "Det finns inte tillräckligt med medel på kortet (Nets utfärdat PLC).",
    "54": "Kortet har gått ut.",
    "55": "Felaktig PIN-kod. Kunden kan försöka igen.",
    "57": "Den begärda tjänsten stöds inte.",
    "58": "Den begärda tjänsten stöds inte.",
    "61": "Det finns inte tillräckligt med medel. Inga fler medel finns tillgängliga i STIP-läge.",
    "63": "Säkerhetsintrång (CSN/CVV).",
    "65": "För många transaktioner. Kortinnehavaren avvisas eftersom inga fler transaktioner är tillåtna i STIP-läge.",
    "68": "Svaret mottogs för sent.",
    "75": "Inga fler PIN-försök tillåtna. PIN-koden har angetts fel 4 gånger i rad.",
    "77": "MAC-fel.",
    "82": "Felaktigt kortformat.",
    "84": "Terminalfel.",
    "85": "Terminalhantering krävs innan avstämning.",
    "87": "Det har gått sju (7) dagar sedan senaste dagsavslut. Gör ett manuellt dagsavslut på betalterminalen genom att trycka Menu och därefter på (6).",
    "88": "Kortet är PIN-baserat, men transaktionen innehöll inte en PIN-block.",
    "89": "Transaktionen innehåller ett PIN-block, men Nets PSP är inte tillåtna att verifiera/omkoda PIN-koden på detta kort.",
    "90": "Avstämning pågår.",
    "91": "Utgivaren är ur funktion. Kortet måste godkännas av utgivaren, men utgivaren går inte att nå.",
    "92": "Okänd utgivare, felaktig rutteringsparameter. Kan också bero på manuell inmatning av kortdata med för få siffror eller felaktig Luhn-siffra.",
    "96": "Systemfel. Fel på Nets PSP-server/tjänster.",
    "99": "Kortet är ogiltigt.",
    "9A": "Tidsstämpeln i transaktionen skiljer sig mer än tillåtet (± 75 minuter).",
    "9B": "BankAxept: För många ackumulerade fallbacks från samma TID. Nytt försök är inte möjligt.",
    "9C": "BankAxept: Fallback-transaktion på grund av chipfel. Försök igen.",
    "9D": "BankAxept: Terminalen är stängd.",
    "9F": "Token kunde inte hittas.",
    "A1": "Kortet kunde inte hittas i utgivardatabasen.",
    "AF": "Kortet är bedrägligt.",
    "AG": "Kortet är bedrägligt. En bankomat kommer att svälja kortet.",
    "D1": "Formateringsfel i PIN-blocket. PIN-koden kan inte verifieras av utgivare eller förvärvare.",
    "D2": "EMV-säkerhetsfel.",
    "D4": "Kryptogramfel.",
    "F1": "Kortet är svartlistat. Kortet avvisas på grund av en post i undantagsfilen.",
    "F2": "Felaktigt KID.",
    "F3": "KID saknas.",
    "F4": "Terminalen är stängd på grund av att fakturan inte är betald eller att terminalen är blockerad på grund av bedräglig terminal. Blockerad på säkerhetsmodulnivå.",
    "F5": "Betalterminalen har ett tekniskt fel. Vänligen kontakta vår tekniska support.",
    "F7": "Kreditkortstransaktionen är inte tillåten. Endast betalkort.",
    "G4": "Bank Axept ingen fallback.",
    "G5": "Bank Axept: EMV-terminalen genererar fallback på Spår 3.",
    "G6": "Bank Axept: Icke-EMV-terminalen genererar fallback på Spår 3.",
    "G8": 'C-less-kort - online PIN krävs. Antalet C-less-transaktioner överskrids. "Fallback" till chip+PIN krävs för att återställa räknaren.',
    "G9": "Mobil C-less. Antal C-less NoCVM överskrids. Lösenord krävs.",
    "GA": "C-less-kort. Chip+PIN krävs.",
  },
  "Reversal Server": {
    "00": "Återbetalning har godkänts.",
    "25": "Den ursprungliga transaktionen kunde inte hittas.",
    "30": "Formateringsfel har uppstått.",
    "77": "MAC-fel.",
    "84": "Den ursprungliga transaktionen avvisades.",
    "86": "Den ursprungliga transaktionen har redan återgåtts.",
    "96": "Systemfel.",
    "F2": "Felaktigt KID.",
  },
  "Reconciliation Server": {
    "00": "Dagsavslut har genomförts. Den aktuella sessionen är avslutad, och nästa session har öppnats (om den inte redan har öppnats från autentiseringsservern genom en autentiseringsförfrågan). Fält S66 talar om antalet transaktioner, och ackumulatorerna för beloppet matchade.",
    "03": "Det angivna BAX-numret är ogiltigt.",
    "30": "Formateringsfel har uppstått.",
    "77": "MAC-fel.",
    "83": "Fel sessionsnummer. Sessionsnumret som används i avstämningsmeddelandet är inte det aktuella numret för den specifika utgivaren. Det är inte möjligt att avstämma andra sessioner än den aktuella öppna sessionen.",
    "90": "Avstämning pågår.",
    "96": "Systemfel.",
  },
  "Off-Line": {
    "Y1": "Offline har godkänts utan att försöka online.",
    "Y3": "Offline har godkänts efter att ha försökt göra transaktionen online.",
    "Z1": "Offline har avvisats (avvisad utan att försöka online).",
    "Z3": "Offline har avvisats efter att ha försökt göra transaktionen online.",
    "Z4": "Transaktionen har avvisats av kortet (efter online godkännande).",
  },
  "Issuer": {
    "01": "Transaktionen har avvisats. Röstbaserad godkännande krävs.",
    "05": "Kortutgivaren har av någon anledning nekat korttransaktionen. Pröva att genomföra transaktionen igen eller be kunden att använda ett annat kort.",
    "12": "Transaktionen är ogiltig. Tjänsten erbjuds inte av banken.",
    "14": "Kortnumret är ogiltigt.",
    "51": "Det finns inte tillräckligt med medel på kortet.",
    "93": "Kortet är blockerat av kortinnehavaren på grund av begränsad användning i område/land.",
    "AI": "Försök igen senare.",
  }
};

export default responseCodes;
