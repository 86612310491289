import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "../css/TerminalModal.css";
import { GiWifiRouter } from "react-icons/gi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import responseCodes from "./NetsResponse";
import printPurchaseReceipt from "./receipts/Purchase";
import printRefundReceipt from "./receipts/Refund";

const TerminalModal = ({
  terminals,
  isOpen,
  closeModal,
  amountToPay,
  amountToRefund,
  token,
  updateTerminalModal,
}) => {
  const [terminalStates, setTerminalStates] = useState({});
  const [fetchingTerminalData, setFetchingTerminalData] = useState(false);
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [terminalChosen, setIsTerminalChosen] = useState(false);
  const [terminalresponsefromcard, setTerminalresponseFromCard] = useState("");
  const [terminalResponseCode, setTerminalResponseCode] = useState("");
  const [terminalResponseCodeText, setTerminalResponseCodeText] = useState("");
  const [isTeminalModalOpen, setIsTeminalModalOpen] = useState(false);
  const [refund, setRefund] = useState(false);

  useEffect(() => {
    setRefund(localStorage.getItem("refund"));
    setSelectedTerminal(null);

    const fetchTerminalStates = async () => {
      try {
        setFetchingTerminalData(true);

        // Retrieve storeData from localStorage and parse it
        const storeData = JSON.parse(localStorage.getItem("storeData"));

        // Ensure storeData is retrieved and contains nets_api_endpoint
        if (!storeData || !storeData.nets_api_endpoint) {
          console.error(
            "storeData or nets_api_endpoint is not found in local storage"
          );
          setFetchingTerminalData(false);
          return;
        }

        const nets_api_endpoint = storeData.nets_api_endpoint;

        const promises = terminals.map(async (terminal) => {
          const response = await fetch(
            `${nets_api_endpoint}/v1/terminal/${terminal}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (!response.ok) {
            throw new Error(
              `Failed to fetch terminal state for terminal ${terminal}`
            );
          }

          const data = await response.json();
          console.log("Terminal data:", data);

          return { terminal, state: data.terminal.terminalState };
        });

        const states = await Promise.all(promises);

        const terminalStateMap = states.reduce((acc, { terminal, state }) => {
          acc[terminal] = state;
          return acc;
        }, {});

        console.log("Terminal states:", terminalStateMap);

        setTerminalStates(terminalStateMap);
        setFetchingTerminalData(false);
      } catch (error) {
        console.error("Error fetching terminal states:", error);
        setFetchingTerminalData(false);
      }
    };

    if (isOpen) {
      fetchTerminalStates();
    }
  }, [isOpen, terminals, token]);

  const handleTerminalSelect = (terminal) => {
    setSelectedTerminal(terminal);
  };

  const closeTerminalModal = () => {
    closeModal(); // Assuming isOpen is the state controlling the modal
    console.log("Terminal modal closed");
  };

  const showterminals = () => {
    setIsTerminalChosen(false);
    setTerminalresponseFromCard("");
  };

  const handlePurchase = () => {
    console.log("Purchase triggered for terminal:", selectedTerminal);
    // Implement purchase logic here
  };

  const getColor = (state) => {
    return state === "disconnected" || state === "unknown" ? "red" : "green";
  };

  const NetsCancel = async (terminal) => {
    setTerminalResponseCodeText("Avbryter...");
    try {
      // Retrieve token and storeData from localStorage
      const netsToken = localStorage.getItem("netsToken");
      const storeData = JSON.parse(localStorage.getItem("storeData"));

      if (!netsToken) {
        throw new Error("Nets token not found in localStorage");
      }

      // Ensure storeData is retrieved and contains nets_api_endpoint
      if (!storeData || !storeData.nets_api_endpoint) {
        console.error(
          "storeData or nets_api_endpoint is not found in local storage"
        );
        setTerminalResponseCodeText("Fel: nets_api_endpoint saknas");
        return;
      }

      const nets_api_endpoint = storeData.nets_api_endpoint;
      const terminalid = selectedTerminal;

      // Send a cancel action request to the terminal
      const response = await fetch(
        `${nets_api_endpoint}/v1/terminal/${terminalid}/administration`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${netsToken}`, // Make sure netsToken is defined
          },
          body: JSON.stringify({
            action: "cancelAction", // Can also use "finishAction" for silent cancellation
          }),
        }
      );

      console.log("Cancellation response:", response);

      // Check if the request was successful
      if (response.ok) {
        console.log(
          "Cancellation request sent successfully: ",
          response.result
        );
        setTerminalResponseCode("200");
        setTerminalResponseCodeText("Avbruten av kassör");
        // Optionally, you can return some confirmation message or handle the cancellation response here
      } else {
        // Handle errors if the cancellation request fails
        console.error("Error cancelling action:", response.statusText);
        setTerminalResponseCodeText("Fel vid avbrytande");
      }
    } catch (error) {
      // Handle network errors or exceptions
      console.error("Error cancelling action:", error);
      setTerminalResponseCodeText("Nätverksfel vid avbrytande");
    }
  };

  const NetsPurchase = async (terminal) => {
    setIsTerminalChosen(true);
    try {
      // Retrieve token and storeData from localStorage
      const netsToken = localStorage.getItem("netsToken");
      const storeData = JSON.parse(localStorage.getItem("storeData"));

      if (!netsToken) {
        throw new Error("Nets token not found in localStorage");
      }

      // Ensure storeData is retrieved and contains nets_api_endpoint
      if (!storeData || !storeData.nets_api_endpoint) {
        console.error(
          "storeData or nets_api_endpoint is not found in local storage"
        );
        setTerminalResponseCodeText("Fel: nets_api_endpoint saknas");
        return;
      }

      const nets_api_endpoint = storeData.nets_api_endpoint;

      if (refund === "true") {
        const response = await fetch(
          `${nets_api_endpoint}/v1/terminal/${terminal}/transaction`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${netsToken}`,
            },
            body: JSON.stringify({
              // transactionType: "returnOfGoods",
              amount: amountToRefund * 100,
            }),
          }
        );

        const responseData = await response.json();
        console.log("Refund response:", responseData);

        if (responseData.result) {
          const localModeEventArgs = responseData.result[0].localModeEventArgs;
          const customerReceipt = responseData.result[0].customerReceipt;
          const responseCode = localModeEventArgs.responseCode;

          setTerminalresponseFromCard(customerReceipt);
          console.log("Terminal Response from Card:", customerReceipt);
          setTerminalResponseCode(responseCode);
          await printRefundReceipt(customerReceipt);

          const terminalResponseCodeText =
            responseCodes["Authorisation Server"][responseCode] || "";
          setTerminalResponseCodeText(terminalResponseCodeText);
        } else if (responseData.failure) {
          const failure = responseData.failure;
          const responseCode = failure.localModeEventArgs.responseCode;
          const customerReceipt = failure.customerReceipt;

          const splitResultData = splitByLength(
            failure.localModeEventArgs.ResultData,
            40
          );

          setTerminalresponseFromCard(customerReceipt);
          setTerminalResponseCode(responseCode);
          await printRefundReceipt(
            `${customerReceipt}\n${failure.error}\n${splitResultData.join(
              "\n"
            )}`
          );

          const terminalResponseCodeText =
            responseCodes["Authorisation Server"][responseCode] ||
            failure.error;
          setTerminalResponseCodeText(terminalResponseCodeText);

          if (!terminalResponseCodeText) {
            console.error(
              "No corresponding text found for response code:",
              responseCode
            );
          }
        }
      } else {
        const response = await fetch(
          `${nets_api_endpoint}/v1/terminal/${terminal}/transaction`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${netsToken}`,
            },
            body: JSON.stringify({
              transactionType: "purchase",
              amount: amountToPay * 100,
              allowPinBypass: false,
              orderId: "10A90015224431",
            }),
          }
        );

        const responseData = await response.json();
        console.log("Purchase response:", responseData);

        if (responseData.result) {
          const localModeEventArgs = responseData.result[0].localModeEventArgs;
          const customerReceipt = responseData.result[0].customerReceipt;
          const responseCode = localModeEventArgs.responseCode;

          setTerminalresponseFromCard(customerReceipt);
          console.log("Terminal Response from Card:", customerReceipt);
          setTerminalResponseCode(responseCode);
          await printPurchaseReceipt(customerReceipt);

          const terminalResponseCodeText =
            responseCodes["Authorisation Server"][responseCode] || "";
          setTerminalResponseCodeText(terminalResponseCodeText);
        } else if (responseData.failure) {
          const failure = responseData.failure;
          const responseCode = failure.localModeEventArgs.responseCode;
          const customerReceipt = failure.customerReceipt;

          const splitResultData = splitByLength(
            failure.localModeEventArgs.ResultData,
            40
          );

          setTerminalresponseFromCard(customerReceipt);
          setTerminalResponseCode(responseCode);
          await printPurchaseReceipt(
            `${customerReceipt}\n${failure.error}\n${splitResultData.join(
              "\n"
            )}`
          );

          const terminalResponseCodeText =
            responseCodes["Authorisation Server"][responseCode] ||
            failure.error;
          setTerminalResponseCodeText(terminalResponseCodeText);

          if (!terminalResponseCodeText) {
            console.error(
              "No corresponding text found for response code:",
              responseCode
            );
          }
        }
      }
    } catch (error) {
      console.error("Error purchasing from Nets:", error);
    }
  };

  function splitByLength(str, length) {
    const result = [];
    for (let i = 0; i < str.length; i += length) {
      result.push(str.substr(i, length));
    }
    return result;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeTerminalModal}
      className="modal"
      overlayClassName="modal-overlay"
    >
      {!terminalChosen ? (
        <div className="modal-content1">
          <h2>Välj betalterminal</h2>

          <div className="terminal-grid">
            {terminals.map((terminal) => (
              <div
                key={terminal}
                className={`terminal-item ${
                  fetchingTerminalData || terminalStates[terminal] !== "idle"
                    ? "disabled"
                    : ""
                }`}
                onClick={
                  !fetchingTerminalData && terminalStates[terminal] === "idle"
                    ? () => {
                        handleTerminalSelect(terminal);
                        setTerminalResponseCodeText(
                          "Följ instruktionerna i terminalen"
                        );
                        setTerminalResponseCode("");
                        // Initiate Nets purchase
                        NetsPurchase(terminal);
                      }
                    : undefined
                }
              >
                <div>Terminal {terminal}</div>
                <GiWifiRouter
                  style={{ color: getColor(terminalStates[terminal]) }}
                />
                <div>
                  Status:{" "}
                  {fetchingTerminalData && (
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                  )}
                  <span
                    className={
                      terminalStates[terminal] === "idle"
                        ? "green-text"
                        : "red-text"
                    }
                  >
                    {terminalStates[terminal] || "Unkown"}
                  </span>
                </div>
                <div>
                  <label>
                    {" "}
                    {refund === "true"
                      ? `Att återbetala: ${amountToRefund}`
                      : `Att betala: ${amountToPay}`}
                  </label>
                </div>
              </div>
            ))}
          </div>
          <button
            onClick={closeTerminalModal}
            className="close-teminal-modal-button"
          >
            X
          </button>
          <div className="update-terminal-grid">
            <button onClick={updateTerminalModal} className="update-button">
              Uppdatera
            </button>
          </div>
        </div>
      ) : (
        <div className="modal-content2">
          <h1>{terminalResponseCodeText}</h1>
          <p>status: {terminalResponseCode}</p>
          <div style={{ whiteSpace: "pre-wrap" }}>
            {terminalresponsefromcard &&
              terminalresponsefromcard.split("\r\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
          </div>

          <button onClick={showterminals} className="showterminals">
            Tillbaka
          </button>
          <button onClick={NetsCancel} className="netscancel">
            Avbryt
          </button>
        </div>
      )}
    </Modal>
  );
};

export default TerminalModal;
