const printRefundReceipt = async (terminalresponsefromcard) => {
  try {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10);
    const formattedTime = currentDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    const storedCartData = localStorage.getItem("cartData");
    const parsedCartData = storedCartData
      ? JSON.parse(storedCartData)
      : { data: [] };

    const totalTax = localStorage.getItem("totalTax") || 0;

    let receiptData = `
  ===========================================
  TEST RECEIPT - ÅTERBETALNING
  ===========================================
  Datum:  ${formattedDate} ${formattedTime}
  Transaction ID: 123456789
  ===========================================
  Artikel                      Antal  Pris
  -------------------------------------------`;

    let subtotal = 0; // Subtotal

    parsedCartData.data.forEach((item) => {
      const discountPercentage = item.discountPercentage;
      const discountAmount = item.discountAmount;
      const discountName = item.discountName;
      const discountedPrice = item.discountedPrice;

      const itemName = item.name;
      const itemQty = item.quantity;
      const itemPrice = discountedPrice
        ? discountedPrice
        : parseFloat(item.price.replace(/"/g, "")); // Removing quotes
      let totalItemPrice = (
        discountedPrice
          ? discountedPrice
          : itemQty * itemPrice +
            (item.addonstotal ? parseFloat(item.addonstotal) * itemQty : 0)
      ).toFixed(2);

      // Update subtotal
      subtotal += parseFloat(totalItemPrice);

      receiptData += `
      ${replaceSpecialChars(itemName).padEnd(25)}${itemQty
        .toString()
        .padStart(4)}${totalItemPrice.toString().padStart(8)}`;

      // Add addons details if present
      if (item.addonstotal) {
        const addonsTotal = parseFloat(item.addonstotal);
        const addonsInCart = item.addonsincart;
        const addonsList = addonsInCart.split("<br/>"); // Split by <br/>

        addonsList.forEach((addon) => {
          const [addonName, addonDetails] = addon.split(":"); // Split by :
          if (addonName && addonDetails) {
            const addonPriceMatch = addonDetails.match(/\d+/); // Extract price
            if (addonPriceMatch) {
              const addonPrice = parseFloat(addonPriceMatch[0]); // Parse price
              const cleanedAddonName = addonName
                .replace(/<\/?b.*?>/g, "")
                .trim(); // Remove HTML tags and trim
              const cleanedAddonDetails = addonDetails
                .replace(/<\/?b.*?>/g, "")
                .trim(); // Remove HTML tags and trim
              receiptData += `
${replaceSpecialChars(cleanedAddonName)}: ${replaceSpecialChars(
                cleanedAddonDetails
              )} `; // Display addon
              // Update totalItemPrice with addon price
              totalItemPrice = (
                parseFloat(totalItemPrice) + addonPrice
              ).toFixed(2);
            }
          }
        });
      }

      // Include discount name and amount if available
      if (discountAmount > 0) {
        receiptData += `-\n${discountName} -${parseFloat(
          discountAmount
        ).toFixed(2)}`;
        totalItemPrice = (parseFloat(totalItemPrice) - discountAmount).toFixed(
          2
        );
      }

      receiptData += `
  `;
    });

    receiptData += `
  -------------------------------------------
  Exkl moms -${(subtotal - totalTax).toFixed(2)}
  Moms      -${totalTax}
  Total   -${subtotal.toFixed(2)}
  -------------------------------------------
  ${replaceSpecialChars(terminalresponsefromcard)}

  
  
  
  
  `;

    const fullCommand = `${receiptData}\r\n`;

    const response = await fetch("http://localhost:5000/send-print", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ print_data: fullCommand }),
    });

    const result = await response.json();
    console.log("Print status:", result.status);
    console.log("från terminalen:", terminalresponsefromcard);
  } catch (error) {
    console.error("Error sending print command:", error.message);
  }
};

function replaceSpecialChars(text) {
  return text
    .replace(/Å/g, "Å")
    .replace(/Ä/g, "Ä")
    .replace(/Ö/g, "Ö")
    .replace(/å/g, "å")
    .replace(/ä/g, "ä")
    .replace(/ö/g, "ö");
}

export default printRefundReceipt;
