import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import he from "he"; // Import the HTML entities library
import "../css/ProductList.css"; // Import your custom CSS for styling
import Cart from "./Cart";
import MiniCart from "./MiniCart"; // Import MiniCart component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping,
  faBorderAll,
  faBox,
  faChair,
  faClose,
  faEraser,
  faList,
  faMinus,
  faPercent,
  faPlus,
  faSpinner,
  faSpoon,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { FaEraser } from "react-icons/fa";
//import Header from './Header'

Modal.setAppElement("#root"); // Set the app element for accessibility

const ProductList = ({
  products,
  categories,
  taxes,
  productAddons,
  setCurrentPage,
}) => {
  //const [products, setProducts] = useState([]);
  //const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // State to track loading status
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isProductModalOpen, setProductModalOpen] = useState(false);
  const [isEditingModalOpen, setEditingModalOpen] = useState(false);
  const [isDeliveryModalOpen, setIsDeliveryModalOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]); // Cart items state
  const [isCartOpen, setIsCartOpen] = useState(true); // Cart open status
  const [selectedCategory, setSelectedCategory] = useState(null); // State for selected category
  const [amount, setAmount] = useState(1);
  const [allCategories, setAllCategories] = useState([]); // Store the fetched categories
  const [isEditing, setIsEditing] = useState(false);
  const [editedProduct, setEditedProduct] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState(""); // State for additional info
  const [newAdditionalInfo, setNewAdditionalInfo] = useState("");
  const [addonstotal, setAddonstotal] = useState(0);
  const [addonsincart, setAddonsInCart] = useState("");
  const [isItemDiscountModalOpen, setItemDiscountModalOpen] = useState(false);
  const [isParkedModalOpen, setParkedModalOpen] = useState(false);
  const [discountItem, setDiscountItem] = useState(null);
  const [discountItemOrigPrice, setDiscountItemOrigPrice] = useState(null);
  const [discountItemAmount, setDiscountItemAmount] = useState(null);

  const [parkedCartData, setParkedCartData] = useState([]);
  const [parkedCarts, setParkedCarts] = useState([]);

  const [hppSessionId, setHppSessionId] = useState("");
  const [currentCartItemId, setCurrentCartItemId] = useState(null);

  const [viewMode, setViewMode] = useState("grid");
  const [viewImagesMode, setViewImagesMode] = useState(false);
  const [viewDescriptionMode, setViewDescriptionMode] = useState(false);

  const [isCMOpen, setCMModalIsOpen] = useState(false);
  const [addonsProductMatch, setAddonsProductMatch] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [order, setOrder] = useState(null);

  const handleSearch = (query) => {
    setSelectedCategory(listCategoryIds);
    setSearchQuery(query);
    // Perform search logic here, such as filtering items based on the search query
    console.log("Performing search with query:", query);
  };

  // Function to open the modal
  const openCMModal = () => {
    setCMModalIsOpen(true);
  };

  const openParkedModal = () => {
    getParkedCarts();
    setParkedModalOpen(true);
  };

  const closeParkedModal = () => {
    setParkedModalOpen(false);
  };

  // Function to close the modal
  const closeCMModal = () => {
    setCMModalIsOpen(false);
  };

  const closeItemDiscountModal = () => {
    setItemDiscountModalOpen(false);
    setDiscountItem(null);
  };

  // useEffect(() => {
  //   // Access the data passed from WordPress
  //   const wooData = window.wooData;

  //   // Add console.log statements to verify data
  //   console.log("wooData:", wooData);
  //   console.log("Products:", wooData.products);
  //   console.log("Categories:", wooData.categories);

  //   // Set products and categories from wooData
  //   setProducts(wooData.products);
  //   setCategories(wooData.categories);

  //   // Set loading to false
  //   setIsLoading(false);
  // }, []);

  useEffect(() => {
    // Retrieve the cart data from local storage
    const retrievedCartData = getCartFromLocalStorage();
    console.log(productAddons);
  }, []); // Include products and productAddons in the dependency array

  const filteredProducts = Array.isArray(products)
    ? products.filter(
        (product) =>
          (selectedCategory
            ? product.categories.some(
                (category) => category.id === selectedCategory
              )
            : true) &&
          product.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const removeFromCart = (cartItemId) => {
    const updatedCart = cartItems.filter(
      (item) => item.cartItemId !== cartItemId
    );
    setCartItems(updatedCart);
    saveCartToLocalStorage(updatedCart);
    setAddonsInCart("");
  };

  const handleEatHere = () => {
    localStorage.setItem("deliverymethod", "eathere");
    setIsDeliveryModalOpen(false);
    openProductModal(selectedProduct); // Call openProductModal with the selected product
    // Additional logic if needed
  };

  const handleTakeAway = () => {
    localStorage.setItem("deliverymethod", "takeaway");
    setIsDeliveryModalOpen(false);
    openProductModal(selectedProduct); // Call openProductModal with the selected product
    // Additional logic if needed
  };

  // Function to handle item discount
  const itemDiscount = (cartItemId, quantity) => {
    const discountedItem = cartItems.find(
      (item) => item.cartItemId === cartItemId
    );

    // Log the entire discounted item
    console.log("Discounted Item:", discountedItem);
    setDiscountItem(discountedItem.name);
    setDiscountItemOrigPrice(
      parseFloat(discountedItem.price) * parseFloat(quantity) +
        parseFloat(discountedItem.addonstotal) * parseFloat(quantity)
    );
    setDiscountItemAmount(quantity);

    if (discountedItem) {
      // Log the product name
      console.log("Discounted Product Name:", discountedItem.name);

      // Set the current cart item ID in the state
      setCurrentCartItemId(cartItemId);

      // Set other state variables...
      setItemDiscountModalOpen(true);
    } else {
      console.log("Item not found for the given item ID:", cartItemId);
    }
  };

  const handleDiscount = (percent, originalPrice, currentCartItemId) => {
    // Check if the current cart item ID is set
    if (currentCartItemId) {
      // Find the index of the discounted item using the current cart item ID
      const indexOfDiscountedItem = cartItems.findIndex(
        (item) => item.cartItemId === currentCartItemId
      );

      // Check if the discounted item is found
      if (indexOfDiscountedItem !== -1) {
        // Calculate the discount amount and new price
        const discountAmount = (percent / 100) * discountItemOrigPrice;
        const discountedPrice = discountItemOrigPrice - discountAmount;
        console.log("price to discount: ", discountItemOrigPrice);
        console.log("discountAmount: ", discountAmount);
        console.log("discountedPrice: ", discountedPrice);

        // Update the existing item in cartItems with discount information
        const updatedCartItems = [...cartItems];
        updatedCartItems[indexOfDiscountedItem] = {
          ...updatedCartItems[indexOfDiscountedItem],
          discountName: "Rabatt: ",
          discountedPrice: discountedPrice,
          discountAmount: discountAmount,
          discountPercentage: percent,
        };

        // Set the updated cart items
        setCartItems(updatedCartItems);

        // Save the updated cart data to local storage
        saveCartToLocalStorage(updatedCartItems);

        // Other actions...
        console.log("Handling discount for item with ID:", currentCartItemId);
      } else {
        console.log("Item not found for the given item ID:", currentCartItemId);
      }
    }

    // Other discount handling logic...

    // Close the discount modal or perform any other actions
    closeItemDiscountModal();
  };

  const gotoSettingsPage = () => {
    setCurrentPage("Settings");
  };

  // Function to list all category IDs separated by commas
  const listCategoryIds = () => {
    return allCategories.map((category) => category.id).join(",");
  };

  const toggleViewMode = () => {
    // Toggle between grid and list view
    setSelectedCategory(listCategoryIds);
    setViewMode(viewMode === "grid" ? "list" : "grid");
  };

  const toggleViewImagesMode = () => {
    setViewImagesMode(viewImagesMode === true ? false : true);
  };
  const toggleViewDescriptionMode = () => {
    setViewDescriptionMode(viewDescriptionMode === true ? false : true);
  };

  const increaseQuantity = (cartItemId) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item.cartItemId === cartItemId) {
        return {
          ...item,
          quantity: item.quantity + 1,
        };
      }
      return item;
    });

    setCartItems(updatedCartItems);
    saveCartToLocalStorage(updatedCartItems);
  };

  const decreaseQuantity = (cartItemId) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item.cartItemId === cartItemId && item.quantity > 1) {
        return {
          ...item,
          quantity: item.quantity - 1,
        };
      }
      return item;
    });
    setCartItems(updatedCartItems);
    saveCartToLocalStorage(updatedCartItems);
  };

  const clearCart = () => {
    setCartItems([]);
    setIsCartOpen(true);
    // Call this function to clear the stored cart items
    saveCartToLocalStorage([]);
    setAddonsInCart("");

    // Clear the delivery method from local storage
    localStorage.removeItem("deliverymethod");
  };

  const getNextOrderNumber = () => {
    const orders = JSON.parse(localStorage.getItem("orders")) || [];
    const highestOrderNumber = orders.reduce(
      (max, order) => Math.max(max, order.order_number || 0),
      0
    );
    return highestOrderNumber + 1;
  };

  // Function to store order number and status in local storage
  const storeOrderNumber = (orderNumber) => {
    const orderData = {
      order_number: orderNumber,
      status: "ongoing",
    };
    const orders = JSON.parse(localStorage.getItem("orders")) || [];
    orders.push(orderData);
    localStorage.setItem("orders", JSON.stringify(orders));
    return orderData;
  };

  const openProductModal = (product) => {
    setOrder(getNextOrderNumber());
    storeOrderNumber(order);
    console.log("orderNummer:", order);

    // Check if deliverymethod is set in localStorage
    const deliveryMethod = localStorage.getItem("deliverymethod");

    // If deliverymethod is not set, show the delivery modal first
    if (!deliveryMethod) {
      setIsDeliveryModalOpen(true);
      setSelectedProduct(product); // Pass the product to the delivery modal
      return; // Stop execution until delivery method is selected
    }

    // Delivery method is set, continue opening the product modal
    setIsDeliveryModalOpen(false);
    setSelectedProduct(product);
    setProductModalOpen(true);
    setAddonstotal(0);

    // Delay to gather checked elements
    const delay = 500; // 0.5 seconds in milliseconds
    setTimeout(() => {
      const checkedElements = document.querySelectorAll(".label-item.checked");
      const combinedCheckedLabels = Array.from(checkedElements)
        .map((element) => element.textContent)
        .join(", ");
      console.log("Only one element is checked: " + combinedCheckedLabels);
    }, delay);
  };

  const closeModal = () => {
    setProductModalOpen(false);
    setEditingModalOpen(false);
    setSelectedProduct(null);
    setAdditionalInfo(""); // Clear additional info
    setAmount(1);
  };

  const openEditModal = (product) => {
    //console.log("Editing Product:", product);
    setEditedProduct(product);
    setIsEditing(true);
    setEditingModalOpen(true);

    // Find the cart item corresponding to the edited product
    const cartItem = cartItems.find(
      (item) => item.cartItemId === product.cartItemId
    );

    // Set the amount to the current quantity in the cart
    if (cartItem) {
      setAmount(cartItem.quantity);

      // Set the additional info to the current additional info in the cart item
      setNewAdditionalInfo(cartItem.additionalInfo || ""); // Set to an empty string if additional info is not present in the cart item
    } else {
      setAmount(1); // Default to 1 if the product is not found in the cart
      setNewAdditionalInfo(""); // Clear the additional info input
    }
  };

  const saveEditedProduct = (editedProduct, newQuantity) => {
    // Find the index of the edited product in the cart
    const productIndex = cartItems.findIndex(
      (item) => item.cartItemId === editedProduct.cartItemId
    );

    if (productIndex !== -1) {
      // If the edited product is found in the cart, update its quantity and additional info
      const updatedCartItems = [...cartItems];
      updatedCartItems[productIndex].quantity = newQuantity;
      updatedCartItems[productIndex].additionalInfo = newAdditionalInfo;

      setCartItems(updatedCartItems);
      // Save the updated cart data to local storage
      saveCartToLocalStorage(updatedCartItems);
    }

    setEditingModalOpen(false);
    setIsCartOpen(true);
    setIsEditing(false);
    //console.log("isEditing: ", isEditing);
    setNewAdditionalInfo("");
  };

  const eraseSearch = () => {
    setSearchQuery("");
  };

  const testPrintReceipt = async () => {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().slice(0, 10);
      const formattedTime = currentDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      const storedCartData = localStorage.getItem("cartData");
      const parsedCartData = storedCartData
        ? JSON.parse(storedCartData)
        : { data: [] };

      const totalTax = localStorage.getItem("totalTax") || 0;

      let receiptData = `
  ===========================================
  TEST RECEIPT
  ===========================================
  Datum:  ${formattedDate} ${formattedTime}
  Transaction ID: 123456789
  ===========================================
  Artikel                 Antal  Pris
  -------------------------------------------`;

      let subtotal = 0; // Subtotal

      parsedCartData.data.forEach((item) => {
        const itemName = item.name;
        const itemQty = item.quantity;
        const itemPrice = parseFloat(item.price.replace(/"/g, "")); // Removing quotes

        let totalItemPrice = (
          itemQty * itemPrice +
          parseFloat(item.addonstotal * itemQty)
        ).toFixed(2);

        // Update subtotal
        subtotal += parseFloat(totalItemPrice);

        receiptData += `
  ${replaceSpecialChars(itemName).padEnd(25)}${itemQty
          .toString()
          .padStart(4)}${totalItemPrice.padStart(8)}`;

        // Add addons details if present
        if (item.addonstotal) {
          const addonsTotal = parseFloat(item.addonstotal);
          const addonsInCart = item.addonsincart;
          const addonsList = addonsInCart.split("<br/>"); // Split by <br/>

          addonsList.forEach((addon) => {
            const [addonName, addonPriceStr] = addon.split(":"); // Split by :
            if (addonName && addonPriceStr) {
              const addonPriceMatch = addonPriceStr.match(/\d+/); // Extract price
              if (addonPriceMatch) {
                const addonPrice = parseFloat(addonPriceMatch[0]); // Parse price
                receiptData += `
    ${replaceSpecialChars(
      addonName.trim().replace(/<\/?b.*?>/g, "")
    )}: +${addonPrice} SEK`; // Display addon
                // Update totalItemPrice with addon price
                totalItemPrice = (
                  parseFloat(totalItemPrice) + addonPrice
                ).toFixed(2);
              }
            }
          });
        }

        // Update subtotal with addons
        subtotal += parseFloat(item.addonstotal * itemQty || 0);

        receiptData += `
  `;
      });

      receiptData += `
  -------------------------------------------
  Exkl moms: $${(subtotal - totalTax).toFixed(2)}
  Moms:      $${totalTax}
  Total:   $${subtotal.toFixed(2)}
  -------------------------------------------
  
  
  
  
  
  `;

      const fullCommand = `${receiptData}\r\n`;

      const response = await fetch("http://localhost:5000/send-print", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ print_data: fullCommand }),
      });

      const result = await response.json();
      console.log("Print status:", result.status);
    } catch (error) {
      console.error("Error sending print command:", error.message);
    }
  };

  function replaceSpecialChars(text) {
    return text
      .replace(/Å/g, "Å")
      .replace(/Ä/g, "Ä")
      .replace(/Ö/g, "Ö")
      .replace(/å/g, "å")
      .replace(/ä/g, "ä")
      .replace(/ö/g, "ö");
  }

  const addDiscountToItem = () => {};

  const addToCart = (product, amount) => {
    // Get all the checked elements
    // Get all the checked elements
    const checkedElements = document.querySelectorAll(".label-item.checked");
    const checkedLabels = Array.from(checkedElements).map((element) => {
      const blockprio = element.blockprio;
      const title = `<b data-title="metadatakey">${element.dataset.title}</b>: `;
      const label = element.textContent;
      return { blockprio, label: `${title} ${label}` };
    });

    // Sort the checkedLabels array first by blockprio, then by title
    checkedLabels.sort((a, b) => {
      const blockprioComparison = a.blockprio - b.blockprio;
      if (blockprioComparison === 0) {
        // If blockprio is the same, sort by title
        const titleA = a.label.match(/<b>(.*?)<\/b>/)[1]; // Extract the title from the HTML
        const titleB = b.label.match(/<b>(.*?)<\/b>/)[1];
        return titleA.localeCompare(titleB);
      }
      return blockprioComparison;
    });

    // Join checked labels with newline separator
    const accumulatedLabels = checkedLabels
      .map((item) => item.label)
      .join("<br/>"); // Use '<br/>' for a line break in HTML

    // Set addonsInCart state with the accumulated labels
    setAddonsInCart(accumulatedLabels);
    // Calculate the sum of addon prices
    const addonPrices = Array.from(checkedElements).map((element) => {
      // Assuming each price is in the format "+X SEK" or "-X SEK"
      const priceText = element.querySelector("p").textContent;
      const priceValue = parseFloat(priceText.replace(/[^\d.-]/g, ""));
      return priceValue;
    });

    const addonTotalPrice = addonPrices.reduce(
      (total, price) => total + price,
      0
    );
    console.log("addonstotalprice: ", addonTotalPrice);
    // Set the addonstotal state with the calculated total price
    setAddonstotal(addonTotalPrice);

    // Check if the item is already in the cart
    const existingCartItem = cartItems.find(
      (item) => item.cartItemId === product.cartItemId
    );

    if (existingCartItem) {
      // If the item exists in the cart, update its quantity
      const updatedCartItems = cartItems.map((item) => {
        if (item.cartItemId === product.cartItemId) {
          return {
            ...item,
            quantity: item.quantity + amount, // Update the quantity
          };
        }
        return item;
      });

      setCartItems(updatedCartItems);
    } else {
      // If the item is not in the cart, add it with the specified quantity
      const cartItem = {
        ...product,
        cartItemId: `${product.id}-${Date.now()}`,
        additionalInfo,
        addonstotal: addonTotalPrice,
        addonsincart: accumulatedLabels, // Add accumulated labels to cart item
        quantity: amount,
      };

      setCartItems([...cartItems, cartItem]);

      // Save the updated cart data to local storage
      saveCartToLocalStorage([...cartItems, cartItem]);
    }

    closeModal();
    setIsCartOpen(true);
  };

  const GoToCheckout = async () => {
    // Show the modal
    setCMModalIsOpen(true);
    // Extract cart item data for checkout
    const cartItemsForCheckout = cartItems.map((item) => ({
      product_id: item.id,
      quantity: item.quantity,
    }));

    // Loop through the cart items and add them to the cart sequentially
    for (const item of cartItemsForCheckout) {
      try {
        const cartUrl = `https://stadensmat.se/?add-to-cart=${item.product_id}`;
        console.log(
          "API https://stadensmat.se/?add-to-cart=${item.product_id}"
        );
        const formData = new FormData();
        formData.append("product_id", item.product_id);
        formData.append("quantity", item.quantity);

        await fetch(cartUrl, {
          method: "POST", // Use POST method to add to cart
          body: formData,
        });
      } catch (error) {
        console.error("Error adding item to cart:", error);
        // Handle the error as needed
      }
    }
    // All items added to cart, open the checkout URL
    window.location.href = "https://stadensmat.se/checkout/";
    // Close the modal after items are added to the cart
    closeCMModal();
  };

  // Function to park the current cart
  const parkCart = () => {
    try {
      // Retrieve the current cart data from local storage
      const currentCartData = JSON.parse(localStorage.getItem("cartData"));
      const currentDeliveryMethod = localStorage.getItem("deliverymethod");
      console.log("currentCartData: ", currentCartData);

      if (
        currentCartData &&
        currentCartData.data &&
        Array.isArray(currentCartData.data) &&
        currentCartData.data.length > 0
      ) {
        // Retrieve existing parked cart data from local storage
        const existingParkedCartData =
          JSON.parse(localStorage.getItem("parkedCartData")) || [];

        // Generate a unique identifier for the new parked cart
        const cartId = `cart_${new Date().getTime()}`;

        // Set the parked cart data state
        setParkedCartData((prevParkedCartData) => {
          // Check if an entry with the same cartId already exists
          if (!prevParkedCartData.some((entry) => entry.cartId === cartId)) {
            return [
              ...prevParkedCartData,
              { cartId, data: currentCartData.data, currentDeliveryMethod },
            ];
          }
          return prevParkedCartData; // No changes if the entry already exists
        });

        // Store the new parked cart data in local storage
        localStorage.setItem(
          "parkedCartData",
          JSON.stringify([
            ...existingParkedCartData,
            { cartId, data: currentCartData.data, currentDeliveryMethod },
          ])
        );

        // Clear the current cart data from local storage
        localStorage.removeItem("cartData");
        clearCart();
      } else {
        console.log("No cart data to park.");
      }
    } catch (error) {
      console.error("Error parking cart:", error);
    }
  };

  const getParkedCarts = () => {
    try {
      const storedParkedCartData = JSON.parse(
        localStorage.getItem("parkedCartData")
      );

      if (storedParkedCartData && Array.isArray(storedParkedCartData)) {
        // Update the state with the list of parked carts
        setParkedCarts(storedParkedCartData);
        return storedParkedCartData;
      } else {
        console.log(
          "No parked carts found or invalid structure in local storage."
        );
        return [];
      }
    } catch (error) {
      console.error("Error reading parkedCartData from local storage:", error);
      return [];
    }
  };

  // Function to retrieve the parked cart data by ID and merge with the current cart items
  const getParkedCartDataById = (cartId) => {
    console.log("Starting");
    setParkedModalOpen(false);

    try {
      const storedParkedCartData = JSON.parse(
        localStorage.getItem("parkedCartData")
      );

      console.log(storedParkedCartData);

      if (storedParkedCartData && storedParkedCartData.length > 0) {
        // Find the parked cart by ID
        const parkedCart = storedParkedCartData.find(
          (cart) => cart.cartId === cartId
        );

        if (parkedCart && parkedCart.data && parkedCart.data.length > 0) {
          // Merge the items from the parked cart with the current cart items
          const updatedCartItems = [...cartItems, ...parkedCart.data];
          const parkedDeliveryMethod = parkedCart.currentDeliveryMethod;
          console.log("parkedDeliveryMethod: ", parkedDeliveryMethod);

          // Update the state with the merged cart items
          setCartItems(updatedCartItems);
          localStorage.setItem("deliverymethod", parkedDeliveryMethod);

          // Remove the items from the parked cart data in local storage
          parkedCart.data = [];

          // If there are no items left, remove the entire cart data
          if (parkedCart.data.length === 0) {
            storedParkedCartData.splice(
              storedParkedCartData.indexOf(parkedCart),
              1
            ); // Remove the entire cart data
          }

          // Save the updated parked cart data to local storage
          localStorage.setItem(
            "parkedCartData",
            JSON.stringify(storedParkedCartData)
          );

          // Save the updated cart items to local storage
          saveCartToLocalStorage(updatedCartItems);

          return parkedCart.data;
        } else {
          console.log("Parked cart not found or empty.");
        }
      } else {
        console.log(
          "No parked cart items found or invalid structure in local storage."
        );
      }
    } catch (error) {
      console.error(
        "Error retrieving parked cart data from local storage:",
        error
      );
    }

    // Return an empty array if there's an error or no parked cart items are found
    return [];
  };

  // Function to save the cart data to local storage
  const saveCartToLocalStorage = (cartData) => {
    try {
      if (!cartData || cartData.length === 0) {
        // If cartData is empty or null, remove the item from local storage
        localStorage.removeItem("cartData");
      } else {
        const cartDataWithExpiry = {
          order_number: order,
          data: cartData,
          expiry: new Date().getTime() + 20 * 60 * 1000, // 20 minutes from now
        };
        localStorage.setItem("cartData", JSON.stringify(cartDataWithExpiry));
      }
      console.log("Cartdata: ", cartData);
    } catch (error) {
      // Handle the error, e.g., log it or display an error message to the user
      console.error("Error saving cart data to local storage:", error);
    }
  };

  const getCartFromLocalStorage = () => {
    try {
      const cartDataWithExpiry = JSON.parse(localStorage.getItem("cartData"));
      console.log("Retrieved cartDataWithExpiry:", cartDataWithExpiry);

      if (
        cartDataWithExpiry &&
        cartDataWithExpiry.expiry > new Date().getTime()
      ) {
        setCartItems(cartDataWithExpiry.data);
        //return cartDataWithExpiry.data; // Return the cart data
      }
    } catch (error) {
      console.error("Error retrieving cart data from local storage:", error);
    }
  };

  const productMeetsBlockCriteria = (product, block) => {
    const { settings } = block;
    const rules = settings.rules || {};

    if (Object.keys(rules).length > 0) {
      const {
        show_in,
        show_in_products,
        show_in_categories,
        exclude_products,
        exclude_products_products,
        exclude_products_categories,
        show_to,
        show_to_user_roles,
        show_to_membership,
      } = rules;

      if (show_in === "all") {
        return true;
      }

      if (
        show_in === "products" &&
        (show_in_products || show_in_categories.length > 0)
      ) {
        const productId = product.id ? product.id.toString() : "";
        const productCategories = product.categories.map((category) =>
          category.id.toString()
        );

        // console.log("Product Categories:", productCategories);
        // console.log("KATEGORIER: ", productCategories);
        if (
          (show_in_products.includes(productId) &&
            show_in_categories.length === 0) ||
          (productCategories.some((category) =>
            show_in_categories.includes(category)
          ) &&
            !exclude_products.includes(productId) &&
            !exclude_products_products.includes(productId) &&
            !exclude_products_categories.some((category) =>
              category.includes(productCategories)
            ) &&
            !show_to.includes(productId) &&
            !show_to_user_roles.includes(productId) &&
            !show_to_membership.includes(productId))
        ) {
          return true;
        }
      }
    }

    return false;
  };

  function getInputByType(
    type,
    selection_type,
    title,
    numbers_max,
    label,
    price,
    price_method,
    defaultcheck,
    blockId,
    addonId
  ) {
    const uniqueIndex = `${blockId}_${addonId}_${type}_${label}`;
    const parsedPrice = price === "" || price === null ? 0 : parseFloat(price);
    const priceDisplay = parsedPrice !== 0 ? `${parsedPrice} SEK` : "0 SEK";
    ///console.log("productAddons: ", productAddons);
    const priceWithSign =
      price_method === "increase"
        ? `+${priceDisplay}`
        : price_method === "decrease"
        ? `-${priceDisplay}`
        : price_method === "product"
        ? `+${priceDisplay}`
        : price_method === "free"
        ? ` ${priceDisplay}`
        : "0 SEK";
    if (type === "text") {
      return null; // Render nothing for "text" type
    }

    const mainblockprio = productAddons.blocks.find(
      (block) => block.id === blockId
    )?.priority;

    const maxclicks = numbers_max != "" ? numbers_max : 0;

    return (
      <div className={`block`} data-block={blockId} key={blockId}>
        <div
          className={`label-item ${defaultcheck === "yes" ? "checked" : ""}`}
          data-title={title}
          data-blockid={blockId}
          data-blockprio={mainblockprio}
          data-checkmax={maxclicks}
          key={uniqueIndex}
          onClick={(e) =>
            toggleClass(
              e,
              selection_type,
              label,
              title,
              defaultcheck,
              maxclicks,
              blockId
            )
          }
        >
          <input
            type="checkbox"
            id={`checkbox_${uniqueIndex}`}
            // Add any other checkbox-related props if needed
          />
          <label htmlFor={`checkbox_${uniqueIndex}`}>{label}</label>

          <p>{priceWithSign}</p>
        </div>
      </div>
    );
  }

  function toggleClass(
    event,
    selection_type,
    label,
    title,
    defaultcheck,
    maxclicks
  ) {
    event.preventDefault(); // Prevent default behavior of label click
    const div = event.currentTarget;
    const blockId = div.closest(".block").getAttribute("data-block");

    const priceElement = div.querySelector("p");
    const priceString = priceElement.textContent;
    const price =
      priceString && priceString.trim() !== "" ? parseFloat(priceString) : 0;

    // Check if the addon is checked before the toggle
    const isAddonChecked = div.classList.contains("checked");

    // Check if the addon was a default selection
    const isDefaultAddon = defaultcheck === "yes";

    // Get the labelItems for this block
    const labelItems = document.querySelectorAll(
      `.block[data-block="${blockId}"] .label-item`
    );

    // Initialize the count of selected items for "single" selection type
    let countCheckedinSingle = 0;

    if (selection_type === "single") {
      // Count the number of selected items when selection_type is "single"
      countCheckedinSingle = [...labelItems].filter((item) =>
        item.classList.contains("checked")
      ).length;

      console.log("singles: ", countCheckedinSingle);
    }

    // If selection_type is "single" in the same block, toggle checked for all label-items
    if (selection_type === "single") {
      if (isAddonChecked) {
        if (isAddonChecked) {
          // If it's a default item, allow toggling
          countCheckedinSingle === 1
            ? div.classList.add("checked")
            : labelItems.forEach((item) => item.classList.remove("checked"));
        } else {
          // For non-default items, uncheck them
          countCheckedinSingle === 1
            ? div.classList.add("checked")
            : labelItems.forEach((item) => item.classList.remove("checked"));
        }
      } else {
        // If it's not checked, toggle it
        if (isAddonChecked) {
          // If it's a default item, we can toggle it
          countCheckedinSingle === 1
            ? div.classList.add("checked")
            : labelItems.forEach((item) => item.classList.remove("checked"));
        } else {
          // For non-default items, uncheck them and toggle the clicked item
          labelItems.forEach((item) => item.classList.remove("checked"));
          countCheckedinSingle === 1
            ? div.classList.add("checked")
            : labelItems.forEach((item) => item.classList.remove("checked"));
        }
      }
    } else {
      // For multiple selection type, simply toggle the checked state
      div.classList.toggle("checked");
    }

    const checkedElements = document.querySelectorAll(".label-item.checked");
    // Calculate the sum of addon prices
    const addonPrices = Array.from(checkedElements).map((element) => {
      // Assuming each price is in the format "+X SEK" or "-X SEK"
      const priceText = element.querySelector("p").textContent;
      const priceValue = parseFloat(priceText.replace(/[^\d.-]/g, ""));
      return priceValue;
    });

    const addonTotalPrice = addonPrices.reduce(
      (total, price) => total + price,
      0
    );
    console.log("addonstotalprice: ", addonTotalPrice);
    // Set the addonstotal state with the calculated total price
    setAddonstotal(addonTotalPrice);
  }

  // Calculate the remaining characters
  const maxCharacters = 160;
  const remainingCharactersnew = maxCharacters - newAdditionalInfo.length;
  // Calculate the remaining characters
  const remainingCharacters = maxCharacters - additionalInfo.length;

  // Calculate total quantity
  const totalQuantity = cartItems.reduce((sum, item) => sum + item.quantity, 0);

  // Calculate total amount from cart items and addons minus discount
  const totalAmount = cartItems.reduce((sum, item) => {
    // Calculate the total price of each item including addons
    const itemTotalPrice =
      parseFloat(item.price) + parseFloat(item.addonstotal || 0);

    return sum + itemTotalPrice * item.quantity;
  }, 0);

  // Calculate total discount amount
  const totalDiscountAmount = cartItems.reduce(
    (sum, item) => sum + (item.discountAmount || 0),
    0
  );

  // Add the total discount amount to the updated total amount
  const updatedTotalAmount = totalAmount - totalDiscountAmount;

  const groupedProducts = {};

  // Check if filteredProducts is an array before using forEach
  if (Array.isArray(filteredProducts)) {
    filteredProducts.forEach((product) => {
      if (product.categories && product.categories.length > 0) {
        const categoryName = product.categories[0].name; // Assuming a product can have only one category
        if (!groupedProducts[categoryName]) {
          groupedProducts[categoryName] = [];
        }
        groupedProducts[categoryName].push(product);
      }
    });
  } else {
    console.error("filteredProducts is not an array.");
  }

  return (
    <div className="product-list-container">
      <div className="main-menu-container">
        <div className="main-menu">
          <div className="main-menu-searchbox">
            <input
              type="text"
              placeholder="Sök produkter..."
              onChange={(e) => handleSearch(e.target.value)}
              value={searchQuery}
              style={{
                padding: 8,
                marginRight: 10,
                borderRadius: 4,
                borderColor: "#005dbf",
                flexGrow: 1,
                maxWidth: 200,
              }}
            />
            {searchQuery !== "" ? (
              <button onClick={eraseSearch}>
                <FontAwesomeIcon icon={faClose} />
              </button>
            ) : null}
          </div>
          {/* Button to toggle view mode */}
          <button onClick={testPrintReceipt}></button>
          <button
            onClick={toggleViewMode}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#005dbf",
              padding: 8,
              backgroundColor: "transparent",
              borderRadius: 4,
              borderColor: "#005dbf",
              margin: 10,
            }}
          >
            {"Visa som"} {viewMode === "grid" ? "meny" : "rutnät"}
            {"\u00A0\u00A0\u00A0\u00A0"}
            {viewMode === "grid" ? (
              <FontAwesomeIcon icon={faList} />
            ) : (
              <FontAwesomeIcon icon={faBorderAll} />
            )}
          </button>
          {viewMode === "grid" ? (
            <>
              <button
                onClick={toggleViewImagesMode}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 8,
                  color: "#005dbf",
                  backgroundColor: "transparent",
                  borderRadius: 4,
                  borderColor: "#005dbf",
                  margin: 10,
                }}
                className="toggle-button"
              >
                {viewImagesMode === true ? "Dölj bilder" : "Visa bilder"}{" "}
              </button>
              <button
                onClick={toggleViewDescriptionMode}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 8,
                  color: "#005dbf",
                  backgroundColor: "transparent",
                  borderRadius: 4,
                  borderColor: "#005dbf",
                  margin: 10,
                }}
                className="toggle-button"
              >
                {viewDescriptionMode === true
                  ? "Dölj beskrivning"
                  : "Visa beskrivning"}{" "}
              </button>
            </>
          ) : null}

          <MiniCart
            cartItems={cartItems}
            isCartOpen={isCartOpen}
            onCloseCart={() => setIsCartOpen(!isCartOpen)}
            setIsCartOpen={setIsCartOpen}
            totalQuantity={totalQuantity}
            totalAmount={totalAmount}
            updatedTotalAmount={updatedTotalAmount}
          />
        </div>
      </div>
      <Modal
        isOpen={isCMOpen}
        onRequestClose={closeCMModal}
        contentLabel="Checkout Modal"
        className="modal"
      >
        <h2>Vänligen vänta... Du skickas nu till en säker checkout.</h2>
        <FontAwesomeIcon icon={faSpinner} spin />

        {/* You can add any additional content or styling for the modal here */}
      </Modal>
      <Modal
        isOpen={isDeliveryModalOpen}
        contentLabel="Delivery Modal"
        className="modal"
      >
        <h2>Äta här eller ta med?</h2>

        <div className="button-container-delivery">
          <button
            className="eat-here-button eat-here-button-primary"
            onClick={handleEatHere}
          >
            Äta här
            <span>
              {" "}
              <FontAwesomeIcon icon={faChair} />
            </span>
          </button>

          <button
            className="take-away-button take-away-button-secondary"
            onClick={handleTakeAway}
          >
            Ta med
            <span>
              {" "}
              <FontAwesomeIcon icon={faBagShopping} />
            </span>
          </button>
        </div>

        {/* You can add any additional content or styling for the modal here */}
      </Modal>
      <Modal
        isOpen={isParkedModalOpen}
        onRequestClose={closeParkedModal}
        className="modal"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content">
          <span className="close" onClick={closeParkedModal}>
            &times;
          </span>
          <h2>Parkerade kvitton</h2>
          <div className="parked-carts-grid">
            {parkedCarts.map((cart, index) => (
              <div
                key={index} // Use index as the key if cartId is not unique
                className="parked-cart-item"
                onClick={() => getParkedCartDataById(cart.cartId)}
              >
                <h3>Kvitto ID: {cart.cartId}</h3>
                <p>Antal produkter: {cart.data.length}</p>
                <ul>
                  {cart.data.map((item, itemIndex) => (
                    <li key={itemIndex}>
                      <p>{item.name}</p>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isItemDiscountModalOpen}
        onRequestClose={closeItemDiscountModal}
        contentLabel="Item Discount Modal"
        className="modalItemDiscount"
        overlayClassName="modalItemDiscount-overlay"
      >
        <h1>
          Sätt rabatt för {discountItemAmount} {" st "}
          {(discountItemAmount > 1 ? discountItem + "'s" : discountItem) +
            " på beloppet " +
            parseFloat(discountItemOrigPrice).toFixed(2)}
        </h1>
        <FontAwesomeIcon icon={faPercent} />

        <div className="discount-buttons">
          {[0, 5, 10, 15, 20, 25, 30, 35, 40, 50, 60, 70, 80, 90, 100].map(
            (percent) => (
              <button
                key={percent}
                className={`discount-button-${percent}`}
                onClick={() =>
                  handleDiscount(
                    percent,
                    discountItemOrigPrice,
                    currentCartItemId
                  )
                }
              >
                {percent}%
              </button>
            )
          )}
        </div>

        <button onClick={closeItemDiscountModal}>Stäng</button>
      </Modal>

      <div
        className={
          viewMode === "grid" ? "category-names-grid" : "category-names"
        }
      >
        {!isLoading && (
          <div
            className={`category-name ${
              selectedCategory === "all" ? "selected" : ""
            } ${viewMode === "grid" ? "category-name-grid" : ""}`}
            onClick={() => {
              setSelectedCategory(listCategoryIds);
              eraseSearch();
            }}
          >
            Allt
          </div>
        )}
        {categories
          .filter((category) => category.count > 0)
          .sort((a, b) => a.menu_order - b.menu_order) // Sort by menu_order ascending
          .map((category) => (
            <div
              key={category.id}
              className={`category-name ${
                selectedCategory === category.id ? "selected" : ""
              } ${viewMode === "grid" ? "category-name-grid" : ""}`}
              onClick={() => {
                setSelectedCategory(category.id);
                eraseSearch();
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: category.name }}></span>{" "}
              ({category.count})
            </div>
          ))}
      </div>

      {isLoading ? (
        // Loading state
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      ) : viewMode === "grid" ? (
        // Grid view
        <div className="product-grid">
          {Array.isArray(filteredProducts) &&
            filteredProducts
              .filter((product) => product.catalog_visibility !== "hidden")
              .sort((a, b) => a.menu_order - b.menu_order)
              .map((product) => (
                <div
                  key={product.id}
                  className={`product-card ${
                    product.stock_status === "outofstock" ? "out-of-stock" : ""
                  }`}
                  onClick={
                    product.stock_status === "outofstock"
                      ? null
                      : () => openProductModal(product)
                  }
                >
                  {viewImagesMode &&
                    product.images &&
                    product.images.length > 0 && (
                      <img src={product.images[0].src} alt={product.name} />
                    )}

                  <h3>{he.decode(product.name)}</h3>
                  {viewDescriptionMode && (
                    <div
                      className="product-list-description"
                      dangerouslySetInnerHTML={{
                        __html: he.decode(
                          product.description || product.short_description
                        ),
                      }}
                    />
                  )}

                  <p className="product-price">
                    {product.price !== product.regular_price ? (
                      <>
                        <span className="discounted-price">
                          {product.price}:-
                        </span>
                        <br />
                        <span className="regular-price-with-discount">
                          {product.regular_price}:-
                        </span>
                      </>
                    ) : (
                      <span className="product-price">
                        {product.regular_price}:-
                      </span>
                    )}
                  </p>
                  {product.stock_status === "outofstock" && (
                    <span className="product-stock-status">
                      {product.stock_status === "instock" ? "i lager" : "slut"}
                    </span>
                  )}
                </div>
              ))}
        </div>
      ) : (
        <div className="product-list-container-listview">
          <div className="product-list">
            {Object.entries(groupedProducts)
              .sort(([categoryA], [categoryB]) => {
                const categoryOrderA =
                  categories.find((cat) => cat.name === categoryA)
                    ?.menu_order || 0;
                const categoryOrderB =
                  categories.find((cat) => cat.name === categoryB)
                    ?.menu_order || 0;
                return categoryOrderA - categoryOrderB;
              })
              .map(([category, products]) => {
                const visibleProducts = products.filter(
                  (product) => product.catalog_visibility !== "hidden"
                );

                if (visibleProducts.length === 0) {
                  return null; // Skip rendering category with no visible products
                }

                // Sort visibleProducts by menu_order
                visibleProducts.sort((a, b) => a.menu_order - b.menu_order);

                return (
                  <div key={category} className="product-category full-width">
                    <h2 className="product-category-listview">{category}</h2>
                    <div className="product-list-items">
                      {visibleProducts.map((product, index) => (
                        <div
                          key={product.id}
                          className={`product-list-item ${
                            index % 2 === 0 ? "left" : "right"
                          }`}
                          onClick={() => openProductModal(product)}
                        >
                          <div className="product-info">
                            <h3 className="product-list-title">
                              {he.decode(product.name)}{" "}
                              {product.stock_status === "outofstock" && (
                                <span className="product-stock-status">
                                  {product.stock_status === "instock"
                                    ? "i lager"
                                    : "slut"}
                                </span>
                              )}
                            </h3>
                            <p className="list-product-price">
                              {product.price}:-
                            </p>
                          </div>
                          <div
                            className="product-list-description"
                            dangerouslySetInnerHTML={{
                              __html: he.decode(
                                product.description || product.short_description
                              ),
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    {visibleProducts.length % 2 === 1 && (
                      <div className="empty-column" />
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      )}

      <Modal
        isOpen={isEditingModalOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Product"
        className="modal"
        overlayClassName="modal-overlay"
      >
        {editedProduct ? (
          <div className="modal-content">
            <div className="modal-content">
              <button className="close-button" onClick={closeModal}>
                X
              </button>
              <h3>{he.decode(editedProduct.name)}</h3>
              {/* Decode HTML entities and render the HTML */}
              <div
                className="product-list-description"
                dangerouslySetInnerHTML={{
                  __html: he.decode(
                    editedProduct.description || editedProduct.short_description
                  ),
                }}
              />
              <div style={{ textAlign: "end" }}>
                <textarea
                  className="additional-info"
                  value={newAdditionalInfo}
                  onChange={(e) =>
                    setNewAdditionalInfo(e.target.value.slice(0, 160))
                  } // Limit to 160 characters
                  placeholder="Särskilda önskemål"
                />
                <div className="character-count">{remainingCharactersnew}</div>
              </div>
              <p className="product-price-modal">
                Pris: {parseFloat(editedProduct.price).toFixed(2)}
              </p>
              <div className="add-to-cart-container">
                <div className="amount-field">
                  <button
                    className="decrement-button"
                    onClick={() => {
                      if (amount > 1) {
                        setAmount(amount - 1);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                  <input
                    type="text"
                    className="amount-input"
                    value={amount}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
                      if (newValue.length <= 3) {
                        setAmount(newValue);
                      }
                    }}
                  />
                  <button
                    className="increment-button"
                    onClick={() => {
                      const newAmount = parseInt(amount, 10) + 1;
                      if (!isNaN(newAmount) && newAmount <= 100) {
                        setAmount(newAmount.toString());
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
                <button
                  className="add-to-cart-button"
                  onClick={() =>
                    saveEditedProduct(editedProduct, parseInt(amount, 10))
                  }
                >
                  Spara ändring
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </Modal>
      <Modal
        isOpen={isProductModalOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Product"
        className="product-modal"
        overlayClassName="modal-overlay"
      >
        {selectedProduct ? (
          <div className="modal-content">
            <button className="close-button" onClick={closeModal}>
              X
            </button>
            <h3>{he.decode(selectedProduct.name)}</h3>
            {/* Decode HTML entities and render the HTML */}
            <div
              className="product-list-description"
              dangerouslySetInnerHTML={{
                __html: he.decode(
                  selectedProduct.description ||
                    selectedProduct.short_description
                ),
              }}
            />
            <div className="block-container">
              {productAddons.blocks
                .filter((block) =>
                  productMeetsBlockCriteria(selectedProduct, block)
                )
                .sort((a, b) => a.priority - b.priority) // Sort by priority
                .map((block) => (
                  <div key={block.id} data-blockprio={block.priority}>
                    <h2>{block.settings.title}</h2>

                    {
                      /* Render addons for this block */
                      productAddons.addons
                        .filter((addon) => addon.block_id === block.id)
                        .map((addon) => (
                          <div key={addon.id}>
                            <div>
                              <h2>{addon.settings.title}</h2>
                              {addon.settings.description || (
                                <h3>{addon.settings.description}</h3>
                              )}
                            </div>
                            <div className="labels-container">
                              {addon.options.default &&
                                addon.options.label
                                  .map((label, index) => ({
                                    label: label,
                                    price_method:
                                      addon.options.price_method[index],
                                    price: addon.options.price[index],
                                    defaultcheck: addon.options.default[index],
                                    title: addon.settings.title[index],
                                  }))
                                  .sort((a, b) =>
                                    a.label.localeCompare(b.label)
                                  )
                                  .map((item, index) => (
                                    <div
                                      key={`${block.id}_${addon.id}_${index}`}
                                    >
                                      {getInputByType(
                                        addon.settings.type,
                                        addon.settings.selection_type,
                                        addon.settings.title_in_cart_opt === ""
                                          ? addon.settings.title
                                          : addon.settings.title_in_cart_opt,
                                        addon.settings.numbers_max,
                                        item.label,
                                        item.price,
                                        item.price_method,
                                        item.defaultcheck,
                                        block.id,
                                        addon.id,
                                        index
                                      )}
                                    </div>
                                  ))}
                            </div>
                          </div>
                        ))
                    }
                  </div>
                ))}
            </div>
            <div style={{ textAlign: "end" }}>
              <textarea
                className="additional-info"
                value={additionalInfo}
                onChange={(e) =>
                  setAdditionalInfo(e.target.value.slice(0, 160))
                } // Limit to 160 characters
                placeholder="Särskilda önskemål"
              />
              <div className="character-count">{remainingCharacters}</div>
            </div>
            <div className="payinfobox">
              <p className="product-price-modal">
                Pris: {parseFloat(selectedProduct.price).toFixed(2)}
              </p>
              <span className="product-addonstotal-modal">
                {addonstotal > 0
                  ? "Alternativ: +" + parseFloat(addonstotal).toFixed(2)
                  : addonstotal < 0
                  ? "Alternativ: " + parseFloat(addonstotal).toFixed(2)
                  : null}
              </span>

              <div className="add-to-cart-container">
                <div className="amount-field">
                  <button
                    className="decrement-button"
                    onClick={() => {
                      if (amount > 1) {
                        setAmount(amount - 1);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                  <input
                    type="text"
                    className="amount-input"
                    value={amount}
                    readOnly={true}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
                      if (newValue.length <= 3) {
                        setAmount(newValue);
                      }
                    }}
                  />
                  <button
                    className="increment-button"
                    onClick={() => {
                      const newAmount = parseInt(amount, 10) + 1;
                      if (!isNaN(newAmount) && newAmount <= 100) {
                        setAmount(newAmount.toString());
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
                <button
                  className="add-to-cart-button"
                  onClick={() =>
                    addToCart(
                      selectedProduct,
                      parseInt(amount, 10),
                      addonstotal
                    )
                  }
                >
                  Lägg i varukorg
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </Modal>

      <Cart
        cartItems={cartItems}
        taxes={taxes}
        onCloseCart={() => setIsCartOpen(!isCartOpen)}
        setHppSessionId={setHppSessionId} // Pass the function itself
        hppSessionId={hppSessionId} // Pass the function itself
        onRemoveFromCart={removeFromCart}
        onItemDiscount={itemDiscount}
        onIncreaseQuantity={increaseQuantity}
        onDecreaseQuantity={decreaseQuantity}
        onClearCart={clearCart}
        isCartOpen={isCartOpen}
        onParkCart={parkCart}
        onGetParked={openParkedModal}
        onGoToCheckout={GoToCheckout}
        openEditModal={openEditModal}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default ProductList;
