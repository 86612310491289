import React, { useState } from "react";
import "../css/PhoneNumberInput.css"; // Import your custom CSS for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSms } from "@fortawesome/free-solid-svg-icons";

function PhoneNumberInput({ session_id, remainingTime }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [enabledDigits, setEnabledDigits] = useState(["CLEAR", "0"]);

  const handleDigitClick = (digit) => {
    if (digit === "CLEAR") {
      setPhoneNumber("");
      setEnabledDigits(["CLEAR", "0", "+"]);
    } else if (enabledDigits.includes(digit)) {
      setPhoneNumber((prevPhoneNumber) => {
        return prevPhoneNumber + digit;
      });

      if (
        digit === "0" &&
        enabledDigits.includes("0") &&
        phoneNumber !== "07" &&
        phoneNumber !== "01"
      ) {
        setEnabledDigits(["1", "7"]);
      } else if (
        (digit === "1" || digit === "7") &&
        enabledDigits.includes(digit)
      ) {
        setEnabledDigits(["0", "2", "3", "6", "9", "CLEAR"]);
      } else if (
        [
          digit === "0" ||
            digit === "2" ||
            digit === "3" ||
            digit === "6" ||
            digit === "6" ||
            digit === "CLEAR",
        ] &&
        enabledDigits.includes(digit) &&
        phoneNumber !== "070" &&
        phoneNumber !== "072" &&
        phoneNumber !== "073" &&
        phoneNumber !== "076" &&
        phoneNumber !== "079"
      ) {
        setEnabledDigits([
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "CLEAR",
        ]);
      }
      if (
        phoneNumber.startsWith("010") ||
        phoneNumber.startsWith("070") ||
        phoneNumber.startsWith("072") ||
        phoneNumber.startsWith("073") ||
        phoneNumber.startsWith("076") ||
        phoneNumber.startsWith("079")
      ) {
        setEnabledDigits([
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "CLEAR",
        ]);
      }
      if (phoneNumber.length === 9) {
        setEnabledDigits(["CLEAR"]);
      }
    }
  };

  const formattedPhoneNumber = (number) => {
    if (number.length === 10) {
      return `${number.slice(0, 4)} ${number.slice(4, 6)} ${number.slice(
        6,
        8
      )} ${number.slice(8, 10)}`;
    }
    return number;
  };

  const SendSMS = async (session_id) => {
    // Define the HPP API endpoint using the session_id
    const apiUrl = `https://api.playground.klarna.com/hpp/v1/sessions/${session_id}/distribution`;
    console.log("sms sessionID: ", session_id);
    console.log(
      "API https://api.playground.klarna.com/hpp/v1/sessions/${session_id}/distribution"
    );

    // Create a Basic Authentication header using the same username and password
    const authHeader = `Basic ${btoa("PK69159_fea83f4a446a:Kd4ekm3eshOaJyNf")}`;

    // Create the request body as an object
    const requestBody = {
      contact_information: {
        phone: phoneNumber,
        phone_country: "se",
      },
      method: "sms",
    };

    // Define the request options
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);

      if (response.ok) {
        // The request was successful; you don't need to parse the response.
        return 1;
      } else {
        // Handle the case where the request was not successful
        throw new Error("Failed to send SMS");
      }
    } catch (error) {
      // Handle any network or request errors and return 1
      console.error("Error sending SMS:", error);
      return 1;
    }
  };

  return (
    <div className="tab-panel">
      <div className="input-field-phone">
        <input
          type="text"
          placeholder="Ange telefonnummer"
          value={formattedPhoneNumber(phoneNumber)}
          readOnly
        />
      </div>
      {phoneNumber.length !== 10 ? (
        <div className="digit-keypad">
          {["1", "2", "3", "4", "5", "6", "7", "8", "9", "", "0", "CLEAR"].map(
            (digit) => (
              <div
                key={digit}
                className={`digit ${
                  enabledDigits.includes(digit) ? "enabled" : "disabled"
                }`}
                onClick={() => handleDigitClick(digit)}
              >
                {digit === "" ? <span>&nbsp;</span> : digit}
              </div>
            )
          )}
        </div>
      ) : (
        <div className="button-containerKlarnaSendSMS">
          <button
            className="buttonKlarnaSendSMS"
            onClick={() => {
              SendSMS(session_id, phoneNumber);
            }}
          >
            Skicka Betalningslänk
            <FontAwesomeIcon icon={faSms} size="3x" />
            {formattedPhoneNumber(phoneNumber)}
          </button>
        </div>
      )}
    </div>
  );
}

export default PhoneNumberInput;
