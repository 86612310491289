import React, { useEffect, useState } from "react";

const Sales = () => {
  const [receipts, setReceipts] = useState([]);
  const [selectedReceipt, setSelectedReceipt] = useState(null);

  useEffect(() => {
    // Retrieve the receipts from localStorage
    const storedReceipts = localStorage.getItem("receipts");
    if (storedReceipts) {
      setReceipts(JSON.parse(storedReceipts));
    }
  }, []);

  const handleRowClick = (receipt) => {
    setSelectedReceipt(receipt);
  };

  const handleClosePopup = () => {
    setSelectedReceipt(null);
  };

  return (
    <div>
      <h1>Historiska kvitton</h1>
      {receipts.length === 0 ? (
        <p>Inga kvitton hittade</p>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th>Datum</th>
                <th>Tid</th>
                <th>Händelse</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {receipts
                .slice()
                .sort((a, b) => {
                  const dateA = new Date(`${a.date}T${a.time}`);
                  const dateB = new Date(`${b.date}T${b.time}`);
                  return dateB - dateA; // Sort in descending order
                })
                .map((receipt, index) => (
                  <tr key={index} onClick={() => handleRowClick(receipt)}>
                    <td>{receipt.date}</td>
                    <td>{receipt.time}</td>
                    <td>Visa detaljer</td>
                    <td>{receipt.total}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      )}

      {selectedReceipt && (
        <div className="popup">
          <div className="popup-content">
            <button onClick={handleClosePopup}>Stäng</button>
            <pre style={{ whiteSpace: "pre-wrap" }}>
              {selectedReceipt.receipt}
            </pre>
          </div>
        </div>
      )}

      <style jsx>{`
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th,
        td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
        tr:hover {
          background-color: #f1f1f1;
          cursor: pointer;
        }
        .popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .popup-content {
          background-color: white;
          padding: 20px;
          border-radius: 5px;
          max-width: 80%;
          max-height: 80%;
          overflow-y: auto;
        }
      `}</style>
    </div>
  );
};

export default Sales;
