import React from "react";

const statusTranslations = {
  WAITING: "Väntar",
  IN_PROGRESS: "Pågår",
  COMPLETED: "Slutförd",
  FAILED: "Misslyckades",
  CANCELLED: "Avbruten",
  ERROR: "Fel",
  DISABLED: "Inaktiverad",
  TIME_UP: "Tidsgräns passerad",
};

const KlarnaQR = ({ klarnaCurrentStatus, total, remainingTime, QrCodeUrl }) => {
  const translatedStatus =
    statusTranslations[klarnaCurrentStatus] || klarnaCurrentStatus;
  return (
    <>
      {klarnaCurrentStatus === "WAITING" ? (
        <>
          <h2>SCANNA QR KODEN</h2>
          <h2>Att betala: {total.toFixed(2)}</h2>
          <img
            style={{
              display: "block",
              margin: "auto",
              backgroundColor: "hsl(0, 0%, 90%)",
              width: "400px",
              transition: "background-color 300ms",
            }}
            src={QrCodeUrl}
            alt="QR Code"
          />
          <img
            src="./klarnapay.png"
            alt="Klarna"
            className="button-image-klarna"
            style={{
              display: "block",
              margin: "auto",
              backgroundColor: "hsl(0, 0%, 90%)",
              width: "auto",
              transition: "background-color 300ms",
            }}
          />
          <h2>STATUS: {translatedStatus}</h2>
        </>
      ) : (
        translatedStatus
      )}
    </>
  );
};

export default KlarnaQR;
