import React, { useState, useEffect } from "react";
import "../css/Cart.css"; // Import your custom CSS for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import he from "he"; // Import the HTML entities library
import Modal from "react-modal";
import {
  faArrowCircleRight,
  faArrowRight,
  faBagShopping,
  faBoxesStacked,
  faCancel,
  faCashRegister,
  faChair,
  faCog,
  faCreditCard,
  faEdit,
  faEnvelope,
  faPercent,
  faQrcode,
  faSignOutAlt,
  faSms,
  faSpinner,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import KlarnaSMS from "./KlarnaSMS";
import KlarnaQR from "./KlarnaQR";
import CountdownTimer from "./CountdownTimer";
import CreateOrderComponent from "./Woocommerce";
import { loginToNets } from "./Nets";
import TerminalModal from "./TerminalModal";
import { useNavigate } from "react-router-dom";

const Cart = ({
  cartItems,
  taxes,
  onCloseCart,
  onRemoveFromCart,
  onItemDiscount,
  onIncreaseQuantity,
  onDecreaseQuantity,
  onClearCart,
  isCartOpen,
  onParkCart,
  onGetParked,
  onGoToCheckout,
  openEditModal,
  setHppSessionId,
  hppSessionId,
  setCurrentPage,
}) => {
  const [itemQuantities, setItemQuantities] = useState({});
  const [isPayOptionsModalOpen, setPayOptionsModalOpen] = useState(false);
  const [isRefundOptionsModalOpen, setRefundOptionsModalOpen] = useState(false);
  const [isKlarnaModalOpen, setKlarnaModalOpen] = useState(false);
  const [QrCodeUrl, setQrCodeUrl] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0); // Initial countdown time in seconds
  const [klarnaCurrentStatus, setKlarnaCurentStatus] = useState(null);
  const [activeKlarnaSession, setIsActiveKlarnaSession] = useState(false);
  const [klarnaManuallyCancelled, setIsKlarnaManuallyCancelled] =
    useState(false); // Variable to track manual cancellation]

  const [isKlarnaCancelButtonDisabled, setIsKlarnaCancelButtonDisabled] =
    useState(false);

  const [settingsClickCounter, setSettingsClickCounter] = useState(0);
  const [woocommerceOrderID, setWoocommerceOrderID] = useState("");

  const [parkedItemCount, setParkedCartCount] = useState(0);
  const [showTerminalModal, setShowTerminalModal] = useState(false);
  const [terminals, setTerminals] = useState([]);
  const [selectedTerminal, setSelectedTerminal] = useState("");
  const [token, setToken] = useState("");
  const [netsLoginSpinner, setNetsLoginSpinner] = useState(false);
  const [amountToPay, setAmountToPay] = useState(0);
  const [amountPayed, setAmountPayed] = useState(0);
  const [amountToRefund, setAmountToRefund] = useState(0);
  // Check if delivery method is set in local storage
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const navigate = useNavigate();

  const closeModal = () => {
    setPayOptionsModalOpen(false);
    setRefundOptionsModalOpen(false);
  };

  const setPayOptionsModal = () => {
    console.log("AmountPayed: ", amountPayed);
    localStorage.setItem("refund", false);
    setPayOptionsModalOpen(true);

    const roundedAmountPayed = parseFloat(amountPayed).toFixed(2);

    if (
      !roundedAmountPayed ||
      roundedAmountPayed === "0" ||
      roundedAmountPayed === total.toFixed(2)
    ) {
      // If amountPayed is null, 0, or equal to the total, set amountToPay to total minus amountPayed
      setAmountToPay((total - roundedAmountPayed).toFixed(2));
    } else {
      // Otherwise, set amountToPay to total minus amountPayed
      setAmountToPay((total - roundedAmountPayed).toFixed(2));
    }
  };

  const setRefundOptionsModal = () => {
    console.log("AmountPayed: ", amountPayed);
    setRefundOptionsModalOpen(true);

    const roundedAmountPayed = parseFloat(amountPayed).toFixed(2);

    if (
      !roundedAmountPayed ||
      roundedAmountPayed === "0" ||
      roundedAmountPayed === total.toFixed(2)
    ) {
      // If amountPayed is null, 0, or equal to the total, set amountToPay to total minus amountPayed
      setAmountToRefund((total - roundedAmountPayed).toFixed(2));
    } else {
      // Otherwise, set amountToPay to total minus amountPayed
      setAmountToRefund((total - roundedAmountPayed).toFixed(2));
    }
  };

  const closeKlarnaModal = () => {
    setPayOptionsModalOpen(false);
    setKlarnaModalOpen(false);
    setIsKlarnaManuallyCancelled(true);
    disableHppSession();
  };

  const closeTerminalModal = () => {
    setShowTerminalModal(false);
  };

  const completeOrder = async () => {
    setShowTerminalModal(false);
    const roundedAmountToPay = parseFloat(amountToPay).toFixed(2);
    const roundedAmountPayed = parseFloat(amountPayed).toFixed(2);
    const newAmountPayed = (
      parseFloat(roundedAmountToPay) + parseFloat(roundedAmountPayed)
    ).toFixed(2);
    const newAmountToPay = (total - parseFloat(newAmountPayed)).toFixed(2);

    setAmountPayed(newAmountPayed);
    setAmountToPay(newAmountToPay);

    // Check if amountToPay is 0
    if (parseFloat(newAmountToPay) === 0) {
      setAmountPayed(0);
      // Close the modal
      setPayOptionsModalOpen(false);
      // Delay execution to ensure modal state is updated
      await new Promise((resolve) => setTimeout(resolve, 0));
      // Clear the cart
      onClearCart(); // Assuming onClearCart is a function to clear the cart
    }
  };

  useEffect(() => {
    const storedDeliveryMethod = localStorage.getItem("deliverymethod");
    if (storedDeliveryMethod) {
      setDeliveryMethod(storedDeliveryMethod);
    } else {
      setDeliveryMethod(""); // Clear the state if no value in localStorage
    }
    try {
      const storedParkedCartData = JSON.parse(
        localStorage.getItem("parkedCartData")
      );

      console.log("Stored Parked Cart Data:", storedParkedCartData);

      if (storedParkedCartData && Array.isArray(storedParkedCartData)) {
        // Calculate the total count of unique parked carts
        const uniqueCartCount = storedParkedCartData.length;

        console.log("Unique Cart Count:", uniqueCartCount); // Log the unique cart count

        // Update the parkedCartCount state
        setParkedCartCount(uniqueCartCount);
      }
    } catch (error) {
      console.error("Error reading parkedCartData from local storage:", error);
    }

    const initialQuantities = cartItems.reduce((quantities, item) => {
      quantities[item.cartItemId] = item.quantity;
      return quantities;
    }, {});
    setItemQuantities(initialQuantities);
    console.log(klarnaManuallyCancelled);
    console.log("taxes in cart", taxes);
  }, [cartItems, klarnaManuallyCancelled]);

  const totalQuantity = Object.keys(itemQuantities).reduce(
    (sum, cartItemId) => sum + itemQuantities[cartItemId],
    0
  );

  const handleKlarnaCheckout = async () => {
    setKlarnaCurentStatus("WAITING");
    setRemainingTime(12);
    // Prepare the cart data for the Klarna request
    const cartData = {
      total: total,
      cartItems: cartItems,
      // ... (other cart-related data)
    };

    try {
      const klarnaResponse = await sendKlarnaRequest(cartData);
    } catch (error) {
      // Handle any errors that occurred during the Klarna request
      console.error("Klarna API Error:", error);
    }
  };

  const moms = (cartItems) => {
    const totalTax = cartItems.reduce((sum, item) => {
      const totalAmount = (
        (parseFloat(item.price) + parseFloat(item.addonstotal || 0)) *
        itemQuantities[item.cartItemId]
      ).toFixed(2);

      const taxRate =
        taxes.find((rate) => rate.class === (item.tax_class || "standard"))
          ?.rate || 0;

      const discountedAmount = (
        totalAmount / (1 + parseFloat(taxRate) / 100) || 1
      ).toFixed(2);

      const momsAmount = (totalAmount - discountedAmount).toFixed(2);

      return sum + parseFloat(momsAmount);
    }, 0);

    // Store total tax in localStorage
    localStorage.setItem("totalTax", totalTax.toFixed(2));

    return totalTax;
  };

  const handlePayCard = async () => {
    try {
      setNetsLoginSpinner(true);
      const storedToken = localStorage.getItem("netsToken");
      const storedTokenTimestamp = localStorage.getItem("netsTokenTimestamp");

      // if (storedToken && storedTokenTimestamp) {
      //   const tokenAge = Date.now() - parseInt(storedTokenTimestamp);
      //   const tokenAgeInHours = tokenAge / (1000 * 60 * 60); // Convert milliseconds to hours

      //   if (tokenAgeInHours > 1) {
      // Token is valid, use it
      setToken(storedToken);
      setNetsLoginSpinner(false);
      setShowTerminalModal(true);
      //console.log("user already stored token: ", storedToken);
      //   return; // Exit function
      // }
      // }

      // If token is not valid or doesn't exist, login again
      const responseData = await loginToNets();
      // Extract token and terminals from response data
      const { token, terminals } = responseData;
      // Store token and current timestamp in localStorage
      localStorage.setItem("netsToken", token);
      localStorage.setItem("netsTokenTimestamp", Date.now());

      // Handle the response data as needed
      console.log("Logged in to Nets API:", responseData);
      // Set terminals in component state
      setTerminals(terminals);
      setToken(token);

      setNetsLoginSpinner(false);
      // Show the terminal modal
      setShowTerminalModal(true);
    } catch (error) {
      // Handle errors
      console.error("Error logging in to Nets API:", error);
      setNetsLoginSpinner(false);
    }
  };

  const handlePayCardRefund = async () => {
    try {
      setNetsLoginSpinner(true);
      localStorage.setItem("refund", true);
      const storedToken = localStorage.getItem("netsToken");
      const storedTokenTimestamp = localStorage.getItem("netsTokenTimestamp");

      // if (storedToken && storedTokenTimestamp) {
      //   const tokenAge = Date.now() - parseInt(storedTokenTimestamp);
      //   const tokenAgeInHours = tokenAge / (1000 * 60 * 60); // Convert milliseconds to hours

      //   if (tokenAgeInHours > 1) {
      // Token is valid, use it
      setToken(storedToken);
      setNetsLoginSpinner(false);
      setShowTerminalModal(true);
      //console.log("user already stored token: ", storedToken);
      //   return; // Exit function
      // }
      // }

      // If token is not valid or doesn't exist, login again
      const responseData = await loginToNets();
      // Extract token and terminals from response data
      const { token, terminals } = responseData;
      // Store token and current timestamp in localStorage
      localStorage.setItem("netsToken", token);
      localStorage.setItem("netsTokenTimestamp", Date.now());

      // Handle the response data as needed
      console.log("Logged in to Nets API:", responseData);
      // Set terminals in component state
      setTerminals(terminals);
      setToken(token);

      setNetsLoginSpinner(false);
      // Show the terminal modal
      setShowTerminalModal(true);
    } catch (error) {
      // Handle errors
      console.error("Error logging in to Nets API:", error);
      setNetsLoginSpinner(false);
    }
  };

  // Assuming cartItems is an array and itemQuantities, taxes are defined
  const total = cartItems.reduce((sum, item) => {
    const itemQuantity = itemQuantities[item.cartItemId];
    return (
      sum +
      (parseFloat(item.price) + parseFloat(item.addonstotal || 0)) *
        itemQuantity -
      parseFloat(item.discountAmount || 0)
    );
  }, 0);

  const handleLoginSuccess = (response) => {
    setTerminals(response.terminals);
    setShowTerminalModal(true);
  };

  const handleTerminalSelect = (terminal) => {
    //setSelectedTerminal(terminal);
    //closeTerminalModal(); // Close modal after selecting terminal
  };

  // Calculate the total moms for all items
  const totalMoms = moms(cartItems);

  //**KLARNA**/

  const sendKlarnaRequest = async (cartData) => {
    if (activeKlarnaSession) {
      console.log("A session is already in progress. Aborting new session.");
      return; // Don't start a new session if one is already active
    }

    setWoocommerceOrderID("");
    // Define the Klarna API endpoint
    const apiUrl = "https://api.playground.klarna.com/payments/v1/sessions";
    console.log("API https://api.playground.klarna.com/payments/v1/sessions");

    const orderLines = cartData.cartItems.map((item) => {
      const imageSrc =
        item.images && item.images.length > 0
          ? item.images[0].src
          : "default-image-src";

      return {
        image_url: imageSrc,
        name: item.name, // + ", Info: " + item.additionalInfo,
        unit_price:
          (parseFloat(item.price) + parseFloat(item.addonstotal)) * 100,
        quantity: item.quantity,
        quantity_unit: "st",
        total_amount:
          (parseFloat(item.price) + parseFloat(item.addonstotal)) *
          item.quantity *
          100,
      };
    });

    console.log("order lines: ", orderLines);

    // Create a Basic Authentication header using the provided username and password
    const authHeader = `Basic ${btoa("PK69159_fea83f4a446a:Kd4ekm3eshOaJyNf")}`;

    const requestBody = {
      acquiring_channel: "IN_STORE",
      order_amount: cartData.total * 100,
      order_lines: orderLines,
      purchase_country: "SE",
      purchase_currency: "SEK",
      intent: "buy",
    };

    console.log("rewuest body: ", requestBody);

    // Define your headers
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", authHeader);

    // Configure the request
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: "follow",
    };

    // Send the POST request to Klarna API with the Authorization header
    try {
      const response = await fetch(apiUrl, requestOptions);

      if (response.ok) {
        // The request was successful; you can process the response here
        const responseData = await response.json();

        createHppSession(responseData.session_id);
        return responseData;
      } else {
        // Handle the case where the request was not successful
        throw new Error("Failed to send the request to Klarna API");
      }
    } catch (error) {
      // Handle any network or request errors
      console.error("Error sending request to Klarna API:", error);
    }
  };

  const createHppSession = async (session_id) => {
    // Define the HPP API endpoint using the session_id
    const apiUrl = `https://api.playground.klarna.com/hpp/v1/sessions/`;
    console.log("API https://api.playground.klarna.com/hpp/v1/sessions/");

    // Create a Basic Authentication header using the same username and password
    const authHeader = `Basic ${btoa("PK69159_fea83f4a446a:Kd4ekm3eshOaJyNf")}`;

    // Create the request body as an object
    const requestBody = {
      payment_session_url: `https://api.playground.klarna.com/payments/v1/sessions/${session_id}`,
    };

    // Define the request options
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);

      if (response.ok) {
        // The request was successful; you can process the response here
        const responseData = await response.json();
        setQrCodeUrl(responseData.qr_code_url);
        await setHppSessionId(responseData.session_id);
        console.log("hppresponse: ", responseData);
        setKlarnaModalOpen(true);
        readHppSession(responseData.session_id);

        return responseData;
      } else {
        // Handle the case where the request was not successful
        throw new Error("Failed to create HPP session");
      }
    } catch (error) {
      // Handle any network or request errors
      console.error("Error creating HPP session:", error);
    }
  };

  let pollingInterval;

  const readHppSession = async (session_id, klarnaManuallyCancelled) => {
    setIsActiveKlarnaSession(true);
    const apiUrl = `https://api.playground.klarna.com/hpp/v1/sessions/${session_id}`;
    const authHeader = `Basic ${btoa("PK69159_fea83f4a446a:Kd4ekm3eshOaJyNf")}`;

    console.log(
      "API https://api.playground.klarna.com/hpp/v1/sessions/${session_id}"
    );
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
    };

    const poll = async () => {
      try {
        if (klarnaManuallyCancelled) {
          clearInterval(pollingInterval);
          setIsActiveKlarnaSession(false);
          return;
        }

        const response = await fetch(apiUrl, requestOptions);

        if (response.ok) {
          const responseData = await response.json();

          setKlarnaCurentStatus(responseData.status);
          console.log(
            "hppSTATUS:",
            responseData.status + " " + responseData.session_id
          );
          console.log("klarnamanually:", klarnaManuallyCancelled);

          // Check if the status is one of the desired statuses
          if (
            [
              "COMPLETED",
              "FAILED",
              "CANCELLED",
              "ERROR",
              "DISABLED",
              "TIME_UP",
            ].includes(responseData.status)
          ) {
            setKlarnaCurentStatus(responseData.status);
            console.log(klarnaCurrentStatus);
            clearInterval(pollingInterval);
            console.log("pollinginterval", pollingInterval);
            setIsActiveKlarnaSession(false);

            console.log("responsData: ", responseData);

            if (responseData.status !== "COMPLETED") {
              setPayOptionsModalOpen(false);
              setKlarnaModalOpen(false);
            }
            if (responseData.status === "COMPLETED") {
              createKlarnaOrder(responseData.authorization_token);
            }
          }
        }
      } catch (error) {
        console.error("Error reading HPP session:", error);
        // You can add error handling logic here, like retrying or reporting the error.
      }
    };

    // Start the initial polling
    pollingInterval = setInterval(poll, 5000);
  };

  const createKlarnaOrder = async (authorization_token) => {
    const apiUrl = `https://api.playground.klarna.com/payments/v1/authorizations/${authorization_token}/order`;
    console.log(
      "API https://api.playground.klarna.com/payments/v1/authorizations/${authorization_token}/order"
    );
    const authHeader = `Basic ${btoa("PK69159_fea83f4a446a:Kd4ekm3eshOaJyNf")}`;
    const cartData = {
      total: total,
      cartItems: cartItems,
      // ... (other cart-related data)
    };
    const orderLines = cartData.cartItems.map((item) => ({
      image_url: item.images[0].src,
      name: item.name, // + ", Info: " + item.additionalInfo,
      unit_price: (parseFloat(item.price) + parseFloat(item.addonstotal)) * 100,
      quantity: item.quantity,
      quantity_unit: "st",
      total_amount:
        (parseFloat(item.price) + parseFloat(item.addonstotal)) *
        item.quantity *
        100,
    }));

    const requestBody = {
      order_amount: cartData.total * 100,
      order_lines: orderLines,
      purchase_country: "SE",
      purchase_currency: "SEK",
    };
    //console.log(requestBody);

    // Define your headers
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", authHeader);

    // Configure the request
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: "follow",
    };

    // Send the POST request to Klarna API with the Authorization header
    try {
      const response = await fetch(apiUrl, requestOptions);

      if (response.ok) {
        // The request was successful; you can process the response here
        const responseData = await response.json();
        console.log("Order Klar: ", responseData);
        readKlarnaOrderDetails(responseData.order_id);
        return responseData;
      } else {
        // Handle the case where the request was not successful
        throw new Error("Failed to send the request to Klarna API");
      }
    } catch (error) {
      // Handle any network or request errors
      console.error("Error sending request to Klarna API:", error);
    }
  };

  const readKlarnaOrderDetails = async (order_id) => {
    const apiUrl = `https://api.playground.klarna.com/ordermanagement/v1/orders/${order_id}`;
    const authHeader = `Basic ${btoa("PK69159_fea83f4a446a:Kd4ekm3eshOaJyNf")}`;
    console.log(
      "API https://api.playground.klarna.com/ordermanagement/v1/orders/${order_id}"
    );
    const cartData = {
      total: total,
      cartItems: cartItems,
      // ... (other cart-related data)
    };

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader,
      },
    };

    try {
      const response = await fetch(apiUrl, requestOptions);

      if (!response.ok) {
        throw new Error(
          `Failed to fetch Klarna order details. Status: ${response.status}`
        );
      }

      const orderDetails = await response.json();

      const extractAddons = (addonsString) => {
        const regex =
          /<b data-title="metadatakey">(.*?)<\/b>\s*:\s*(.*?)\s*SEK/g;
        const addonsArray = [];
        let match;
        let index = 1;

        while ((match = regex.exec(addonsString)) !== null) {
          const key = `Extra tillbehör[${index}]`;
          const value = match[2];
          addonsArray.push({ key, value });
          index++;
        }

        return addonsArray.reduce((acc, addon) => {
          acc[addon.key] = addon.value;
          return acc;
        }, {});
      };

      const woocommerceOrderData = {
        // Map the relevant values from Klarna to WooCommerce data
        payment_method: "klarna", // Assuming Klarna corresponds to a specific payment method in WooCommerce
        payment_method_title: "Klarna", // Customize as needed
        set_paid: true,
        billing: {
          first_name: orderDetails.billing_address.given_name,
          last_name: orderDetails.billing_address.family_name,
          address_1: orderDetails.billing_address.street_address,
          address_2: orderDetails.billing_address.street_address2,
          city: orderDetails.billing_address.city,
          state: orderDetails.billing_address.region,
          postcode: orderDetails.billing_address.postal_code,
          country: orderDetails.billing_address.country,
          email: orderDetails.billing_address.email,
          phone: orderDetails.billing_address.phone,
        },
        shipping: {
          first_name: orderDetails.shipping_address.given_name,
          last_name: orderDetails.shipping_address.family_name,
          address_1: orderDetails.shipping_address.street_address,
          address_2: orderDetails.shipping_address.street_address2,
          city: orderDetails.shipping_address.city,
          state: orderDetails.shipping_address.region,
          postcode: orderDetails.shipping_address.postal_code,
          country: orderDetails.shipping_address.country,
          // Add more shipping details as needed
        },

        line_items: cartData.cartItems.map((item) => {
          const addonsObject = extractAddons(item.addonsincart);

          return {
            product_id: item.id,
            quantity: item.quantity,
            price: (
              (parseFloat(item.price) + parseFloat(item.addonstotal)) /
              (taxes.find(
                (rate) => rate.class === (item.tax_class || "standard")
              )?.rate /
                100 +
                1 || 1)
            ).toFixed(2),
            total: (
              ((parseFloat(item.price) + parseFloat(item.addonstotal)) /
                (taxes.find(
                  (rate) => rate.class === (item.tax_class || "standard")
                )?.rate /
                  100 +
                  1 || 1)) *
              item.quantity
            ).toFixed(2),
            meta_data: Object.entries(addonsObject).map(([key, value]) => ({
              key: key,
              value: value,
            })),
          };
        }),
        // Add more order details as needed
      };

      try {
        await createWoocommerceOrder(woocommerceOrderData);
      } catch (error) {
        console.error("Error handling order creation:", error);
      }
      console.log("WooData: ", woocommerceOrderData);

      return orderDetails;
    } catch (error) {
      console.error("Error fetching Klarna order details:", error);
      throw error; // You can handle the error in the calling code
    }
  };

  const createWoocommerceOrder = async (woocommerceOrderData) => {
    if (!woocommerceOrderData) {
      console.error("Order data is missing.");
      return;
    }

    const apiUrl = "https://stadensmat.se/wp-json/wc/v3/orders";
    const consumerKey = "ck_f9114f3d8e029773afb71b39cc7c2fac81d4eca9";
    const consumerSecret = "cs_b67b13d4b4d9b80223b6f1b1445ff030ed8209b4";
    console.log("API https://stadensmat.se/wp-json/wc/v3/orders");

    try {
      const response = await fetch(
        `${apiUrl}?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(woocommerceOrderData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create order");
      }

      const responseData = await response.json();
      setWoocommerceOrderID(responseData.id);
      sendOrderToApp(responseData.id);
      console.log("Response:", responseData);
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  const handleButtonClick = (buttonName) => {
    console.log(`Clicked on ${buttonName} button`);
    // Add logic to handle the button click (navigate, show/hide components, etc.)
  };

  const handleLogout = () => {
    console.log("Clicked on Log out button");
    try {
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
    // Add logic to handle the logout (clear session, redirect, etc.)
  };

  const sendOrderToApp = async (order_id) => {
    const apiUrl = `https://stadensmat.se/wp-json/atWoonce/v1/sendordertoapp`;
    console.log("API https://stadensmat.se/wp-json/atWoonce/v1/sendordertoapp");
    const requestBody = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any additional headers if required by your server
      },
      credentials: "include", // Include this line if your server requires credentials
      body: JSON.stringify({
        order_id: order_id.toString(),
      }),
    };

    try {
      const response = await fetch(apiUrl, requestBody);

      if (!response.ok) {
        throw new Error(
          `Failed to send order to app. Status: ${response.status}`
        );
      }

      const responseData = await response.json();
      console.log("Response from sending order to app:", responseData);
    } catch (error) {
      console.error("Error sending order to app:", error.message);
      // Handle the error as needed
    }
  };

  const disableHppSession = async () => {
    if (
      ![
        "COMPLETED",
        "FAILED",
        "CANCELLED",
        "ERROR",
        "DISABLED",
        "TIME_UP",
      ].includes(klarnaCurrentStatus)
    ) {
      const apiUrl = `https://api.playground.klarna.com/hpp/v1/sessions/${hppSessionId}`;
      const authHeader = `Basic ${btoa(
        "PK69159_fea83f4a446a:Kd4ekm3eshOaJyNf"
      )}`;
      console.log(
        "API https://api.playground.klarna.com/hpp/v1/sessions/${hppSessionId}"
      );
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
      };

      try {
        const response = await fetch(apiUrl, requestOptions);

        if (response.ok) {
          setKlarnaCurentStatus("DISABLED");
          console.log(
            `Successfully disabled HPP session with ID: ${hppSessionId}`
          );
        } else {
          console.error(
            `Failed to disable HPP session with ID: ${hppSessionId}`
          );
        }
      } catch (error) {
        console.error("Error disabling HPP session:", error);
        // You can add error handling logic here, like retrying or reporting the error.
      }
    }
  };

  const gotoLoginafter12 = () => {
    setSettingsClickCounter((prevCounter) => prevCounter + 1);

    if (settingsClickCounter === 11) {
      setSettingsClickCounter(0);
      // Check if the counter is equal to 11 (12 times clicked)
      setCurrentPage("Settings");
      // Navigate to the SettingsLogin page
    }
  };

  const handleServering = () => {
    if (deliveryMethod === "takeaway") {
      localStorage.setItem("deliverymethod", "eathere");
      setDeliveryMethod("eathere");
    }
    if (deliveryMethod === "eathere") {
      localStorage.setItem("deliverymethod", "takeaway");
      setDeliveryMethod("takeaway");
    }
  };

  const statusTranslations = {
    WAITING: "Väntar",
    IN_PROGRESS: "Pågår",
    COMPLETED: "Slutförd",
    FAILED: "Misslyckades",
    CANCELLED: "Avbruten",
    ERROR: "Fel",
    DISABLED: "Inaktiverad",
    TIME_UP: "Tidsgräns passerad",
  };

  const translatedStatus =
    statusTranslations[klarnaCurrentStatus] || klarnaCurrentStatus;

  return (
    <div className={`cart-overlay ${isCartOpen ? "open" : ""}`}>
      <div className="cart">
        {/*
        <button className="close-cart" onClick={onCloseCart}>
          <FontAwesomeIcon icon={faArrowCircleRight} size="3x" />
        </button>
  */}
        <h2 onClick={gotoLoginafter12}>Order</h2>
        {deliveryMethod && (
          <button className="servering-button" onClick={handleServering}>
            Servering:{" "}
            {deliveryMethod === "eathere" ? (
              <span>
                Äta här <FontAwesomeIcon icon={faChair} />
              </span>
            ) : (
              <span>
                Ta med <FontAwesomeIcon icon={faBagShopping} />
              </span>
            )}
          </button>
        )}
        {cartItems.length > 0 ? (
          <button className="clear-cart-button" onClick={onClearCart}>
            <FontAwesomeIcon icon={faTrash} /> Rensa allt
          </button>
        ) : (
          <p>Inga produkter än</p>
        )}
        <div className="cart-items-container">
          <ul className="cart-items">
            {cartItems.map((item) => (
              <li className="cart-item" key={item.cartItemId}>
                <div className="item-row">
                  <div className="item-details">
                    <p
                      className="item-title"
                      onClick={() => openEditModal(item)}
                    >
                      {he.decode(item.name)}{" "}
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={() => openEditModal(item)}
                      />
                    </p>

                    <div className="quantity-container">
                      <p className="item-quantity">
                        {parseFloat(item.price) + parseFloat(item.addonstotal)}{" "}
                      </p>
                      <div className="quantity-buttons">
                        <button
                          onClick={() => onDecreaseQuantity(item.cartItemId)}
                          disabled={item.discountAmount > 0}
                        >
                          -
                        </button>
                        <span>{itemQuantities[item.cartItemId]}st</span>
                        <button
                          onClick={() => onIncreaseQuantity(item.cartItemId)}
                          disabled={item.discountAmount > 0}
                        >
                          +
                        </button>
                      </div>
                    </div>

                    <p className="item-price">
                      {(
                        (parseFloat(item.price) +
                          parseFloat(item.addonstotal)) *
                          itemQuantities[item.cartItemId] -
                        (parseFloat(item.discountAmount) || 0)
                      ).toFixed(2)}{" "}
                    </p>

                    {/* Commented out code block
<p>
  {"("}
  {(
    (
      (parseFloat(item.price) +
        parseFloat(item.addonstotal)) *
      itemQuantities[item.cartItemId]
    ).toFixed(2) -
    (
      ((parseFloat(item.price) +
        parseFloat(item.addonstotal)) *
        itemQuantities[item.cartItemId]) /
      (taxes.find(
        (rate) =>
          rate.class === (item.tax_class || "standard")
      )?.rate /
        100 +
        1 || 1)
    ).toFixed(2)
  ).toFixed(2)}
  {" moms)"}
</p>
*/}
                  </div>
                  {item.additionalInfo && (
                    <p className="item-info">
                      <b>Info: </b>
                      {item.additionalInfo}
                    </p>
                  )}
                  <div
                    className="item-addons-container"
                    dangerouslySetInnerHTML={{ __html: item.addonsincart }}
                  ></div>
                  {item.discountAmount > 0 && (
                    <div
                      className="item-discount-container"
                      dangerouslySetInnerHTML={{
                        __html: `<strong>${item.discountName}</strong> (${
                          item.discountPercentage
                        }%) ${item.discountAmount.toFixed(2)} SEK `,
                      }}
                    ></div>
                  )}
                </div>
                <button
                  className="item-note"
                  onClick={() => openEditModal(item)}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => openEditModal(item)}
                  />
                </button>
                <button
                  className="cartdiscount"
                  onClick={() => onItemDiscount(item.cartItemId, item.quantity)}
                >
                  <FontAwesomeIcon icon={faPercent} />
                </button>
                <button onClick={() => onRemoveFromCart(item.cartItemId)}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </li>
            ))}
          </ul>
          {cartItems.length >= 0 && (
            <>
              <div className="cart-container">
                <div className="cart-add-to-cart">
                  <button
                    disabled={total <= 0}
                    onClick={() => setPayOptionsModal()}
                  >
                    {"BETALA\u00A0\u00A0\u00A0\u00A0"}{" "}
                    <FontAwesomeIcon icon={faCashRegister} />
                  </button>
                </div>
                <div className="cart-total">
                  {" "}
                  {/* onClick={() => onCloseCart()*/}
                  <div className="cart-buttons">
                    <button
                      className="products-button"
                      onClick={() => handleButtonClick("Products")}
                    >
                      <FontAwesomeIcon icon={faBoxesStacked} /> Produkter
                    </button>
                    <button
                      className="settings-button"
                      onClick={() => setCurrentPage("Settings")}
                    >
                      <FontAwesomeIcon icon={faCog} /> Inställningar
                    </button>
                    <button
                      className="users-button"
                      onClick={() => handleButtonClick("Users")}
                    >
                      <FontAwesomeIcon icon={faUser} /> Användare
                    </button>
                    <button
                      className="logout-button"
                      onClick={() => handleLogout()}
                    >
                      <FontAwesomeIcon icon={faSignOutAlt} /> Logga ut
                    </button>
                  </div>
                  <div className="cart-info">
                    <p id="total-price">
                      {totalQuantity}{" "}
                      {totalQuantity === 1 ? "produkt" : "produkter"}, Totalt:{" "}
                      {total.toFixed(2)}
                    </p>
                    <p className="momstotal">
                      varav moms: {totalMoms.toFixed(2)}
                    </p>
                  </div>
                </div>

                {/* Grid of buttons */}
                <div className="button-grid">
                  <button>DELA</button>
                  <button>RABATT</button>
                  <button onClick={() => onParkCart()}>
                    PARKERA ({parkedItemCount})
                  </button>
                  <button disabled={total > 0} onClick={() => onGetParked()}>
                    HÄMTA KVITTO
                  </button>
                  <button>BORD</button>
                  <button onClick={() => setRefundOptionsModal()}>RETUR</button>
                  <button>Button 7</button>
                  <button>Button 8</button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        isOpen={isPayOptionsModalOpen}
        onRequestClose={closeModal}
        contentLabel="Checkout Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>VÄLJ BETALNINGSMETOD</h2>
        <label>BETALAT: </label> {amountPayed}
        <label>KVAR ATT BETALA: </label>
        <input
          className="amounttopay-modal"
          type="number"
          value={amountToPay}
          onChange={(e) => {
            const newValue = parseFloat(e.target.value);
            setAmountToPay(newValue);
          }}
          min="0"
          max={total.toFixed(2)}
          step="0.01"
        />
        <button className="close-button" onClick={closeModal}>
          X
        </button>
        <div className="button-container">
          <button className="button">
            I Kassan
            <FontAwesomeIcon
              style={{ marginTop: "15px" }}
              icon={faCashRegister}
              size="5x"
            />
          </button>
          <button onClick={handlePayCard} className="button">
            Kort
            <img src="./visa.svg" alt="Visa" className="button-image-klarna" />
            <img
              src="./mastercard.svg"
              alt="Mastercard"
              className="button-image-klarna"
            />
            {netsLoginSpinner && (
              <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
            )}
          </button>
          <button className="button" onClick={handleKlarnaCheckout}>
            Klarna
            <img
              src="./klarna-svgrepo-com.svg"
              alt="Klarna"
              className="button-image-klarna"
            />
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isRefundOptionsModalOpen}
        onRequestClose={closeModal}
        contentLabel="Checkout Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>RETUR</h2>
        <label>ATT RETURNERA: </label>
        <input
          className="amounttopay-modal"
          type="number"
          value={amountToRefund}
          onChange={(e) => {
            const newValue = parseFloat(e.target.value);
            setAmountToRefund(newValue);
          }}
          min="0"
          max={total.toFixed(2)}
          step="0.01"
        />
        <button className="close-button" onClick={closeModal}>
          X
        </button>
        <div className="button-container">
          <button onClick={handlePayCardRefund} className="button">
            Kort
            <img src="./visa.svg" alt="Visa" className="button-image-klarna" />
            <img
              src="./mastercard.svg"
              alt="Mastercard"
              className="button-image-klarna"
            />
            {netsLoginSpinner && (
              <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
            )}
          </button>
        </div>
      </Modal>
      <TerminalModal
        terminals={terminals}
        isOpen={showTerminalModal}
        closeModal={closeTerminalModal}
        //selectTerminal={handleTerminalSelect}
        token={token}
        amountToPay={amountToPay} // Pass the token as a prop to TerminalModal
        amountToRefund={amountToRefund}
        updateTerminalModal={handlePayCard}
      />
      <Modal
        isOpen={isKlarnaModalOpen}
        onRequestClose={closeKlarnaModal}
        contentLabel="Checkout Modal"
        className="modal-klarna"
        overlayClassName="modal-overlay"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
      >
        {/* <button className="close-button" onClick={closeModal}>
  X
</button> */}

        {klarnaCurrentStatus == null || klarnaCurrentStatus === "WAITING" ? (
          <Tabs>
            <TabList>
              <Tab>
                Scanna QR Koden <FontAwesomeIcon icon={faQrcode} size="1x" />
              </Tab>
              <Tab>
                SMS <FontAwesomeIcon icon={faSms} size="1x" />
              </Tab>
              <Tab>
                E-post <FontAwesomeIcon icon={faEnvelope} size="1x" />
              </Tab>
            </TabList>
            <TabPanel>
              <KlarnaQR
                klarnaCurrentStatus={klarnaCurrentStatus}
                total={total}
                remainingTime={remainingTime}
                QrCodeUrl={QrCodeUrl}
              />
            </TabPanel>
            <TabPanel>
              <h2>SKICKA BETALNINGSLÄNK</h2>
              <h2>Att betala: {total.toFixed(2)}</h2>
              <KlarnaSMS
                session_id={hppSessionId}
                remainingTime={remainingTime}
              />
            </TabPanel>
            <TabPanel>{/* Content for the E-post tab goes here */}</TabPanel>
          </Tabs>
        ) : klarnaCurrentStatus === "COMPLETED" ? (
          <div>
            {woocommerceOrderID === "" ? (
              <>
                <p>
                  Ett litet ögonblick! Vi slutför din beställning och skickar
                  till köket!
                </p>
                <div className="spinnerLoader">
                  <p>
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                  </p>
                </div>
              </>
            ) : (
              <>
                <h1>Tack!</h1>
                <div className="orderNoBox">
                  <h2>Ditt ordernummer:</h2>
                  <h2 className="wooordernr">{woocommerceOrderID}</h2>
                  <span>Status: {translatedStatus}</span>
                </div>
                {/* Add content for other status */}
              </>
            )}
          </div>
        ) : klarnaCurrentStatus === "DISABLED" ? (
          <>
            <h2>Beställning Avbruten</h2>
            <span>Status: </span>
            <h2>{translatedStatus}</h2>
            <img
              src="./cancelledKlarna.jpg"
              alt="Cancelled"
              className="button-image-smartphone"
              style={{
                display: "block",
                margin: "auto",
                width: "auto",
                height: "450px",
                transition: "background-color 300ms",
              }}
            />
          </>
        ) : (
          <>
            <h2>Slutför betalningen via din telefon</h2>
            <span>Status: </span>
            <h2>{translatedStatus}</h2>
            <img
              src="./smartphone.png"
              alt="SmartPhone"
              className="button-image-smartphone"
              style={{
                display: "block",
                margin: "auto",
                width: "auto",
                transition: "background-color 300ms",
              }}
            />
          </>
        )}

        <div className="button-containerKlarna">
          {klarnaCurrentStatus === "COMPLETED" ? (
            <button
              className="buttonKlarnaCompleted"
              onClick={async () => {
                setIsKlarnaCancelButtonDisabled(true); // Disable the button
                await disableHppSession(hppSessionId);
                setIsKlarnaCancelButtonDisabled(false);

                setPayOptionsModalOpen(false);
                setKlarnaModalOpen(false);
                onClearCart();
                // Re-enable the button after the operation is complete
              }}
              disabled={isKlarnaCancelButtonDisabled} // Set the disabled attribute based on the state
            >
              Slutför
            </button>
          ) : (
            <button
              className="buttonKlarnaCancel"
              onClick={async () => {
                setIsKlarnaCancelButtonDisabled(true); // Disable the button
                await disableHppSession(hppSessionId);
                setIsKlarnaCancelButtonDisabled(false); // Re-enable the button after the operation is complete
              }}
              disabled={isKlarnaCancelButtonDisabled} // Set the disabled attribute based on the state
            >
              {klarnaCurrentStatus === "DISABLED" ? (
                <>
                  Avbryter...{" "}
                  <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                </>
              ) : (
                "Avbryt"
              )}
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Cart;
